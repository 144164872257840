.faqAccordion.ui.accordion {
  margin: 20px 0;
  padding: 15px;
  background: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.faqAccordion.ui.accordion > p {
  margin: 20px 0;
  text-align: center;
  font-size: var(--normal);
}

.faqAccordion.ui.accordion > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--large);
}

.faqAccordion.ui.accordion > .title.activeItem {
  color: var(--main-color);
}

.faqAccordion.ui.accordion > .content > p {
  font-size: var(--large);
}
