.calendarContainer {
  background: var(--neutral-color);
  border: solid 2px var(--border-color);
  left: 5%;
  padding-bottom: 1.5vh !important;
  position: absolute;
  width: 95%;
  z-index: 100;
}

.daysNames {
  padding: 5px !important;
  text-transform: capitalize;
  padding: 0.5em 0;
  font-size: var(--normal);
  font-weight: bold;
  line-height: 1.65;
  text-align: center;
}

.daysNumbers {
  padding: 5px !important;
  text-align: center !important;
}

.disabled {
  color: #fff;
  pointer-events: none;
}

.selected {
  color: #0379ba !important;
  font-weight: bold;
}

.disabled > .number {
  cursor: pointer;
  font-size: var(--large);
  line-height: 1.65;
  color: var(--border-color);
}

.number {
  cursor: pointer;
  font-size: var(--large);
  line-height: 1.65;
  color: var(--button-color);
}

.number:hover {
  color: var(--button-color) !important;
}

.headerCalendar {
  color: var(--main-color);
  font-size: var(--xxlarge);
  font-weight: bold;
  line-height: 1.3;
  padding: 1rem 5px !important;
}

.calendarHeaderButton {
  align-items: center !important;
  color: var(--main-color) !important;
  cursor: pointer !important;
  display: flex !important;
  font-size: var(--small) !important;
  font-weight: bold;
  line-height: 1.3;
  justify-content: center !important;
  padding: 1rem 5px !important;
  text-align: center;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .calendarContainer {
    left: 6%;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .calendarContainer {
    left: 6%;
  }
}
