.scenarioInformation {
  width: 100%;
  margin-top: 2vh;
}

.scenarioInformationContent {
  padding: 2vh 5vh;
  background-color: var(--neutral-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.scenarioInformationContent:first-child {
  margin-bottom: 10px;
}

.scenarioComparisonContent {
  max-width: 100%;
}

.scenarioTableContainer {
  margin: 50px 0;
  width: 100%;
}

.scenarioGraphContainer {
  border-radius: 25px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.rv-discrete-color-legend-item.horizontal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.customTable {
  margin-bottom: 5vh;
}

.customTable.smallTable {
  margin-top: 5vh;
}

.customTable > .body {
  display: flex;
  width: 100%;
  font-size: var(--normal);
  border-top: 1px solid var(--neutral-color);
}

.customTable > .body:first-child {
  margin: 0;
  border: none;
}

.customTable > .body:last-child {
  border-top: 1px solid var(--neutral-color);
  border-bottom: 2px solid var(--main-color);
}

.customTable > .body:last-child > .sectionConfigurations > .row:last-child {
  border-bottom: none;
}

.customTable > .body > .sectionName {
  width: 5%;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customTable.smallTable > .body > .sectionName {
  width: 20%;
}

.customTable > .body > .sectionName > p {
  transform: rotate(-90deg);
  white-space: nowrap;
  color: var(--neutral-color);
  font-weight: bold;
}

.customTable.smallTable > .body > .sectionName > p {
  transform: rotate(0);
  text-align: center;
  word-break: break-word;
}

.customTable > .body > .sectionConfigurations {
  width: 95%;
}

.customTable.smallTable > .body > .sectionConfigurations {
  width: 80%;
}

.customTable > .body > .sectionConfigurations > .row {
  display: flex;
  min-height: 40px;
  border-bottom: 1px solid var(--main-color);
  border-right: 1px solid var(--main-color);
}

.headerCustomTable {
  background-color: var(--main-color);
  white-space: nowrap;
  color: var(--neutral-color);
  font-weight: bold;
}

.customTable > .body > .sectionConfigurations > .row:first-child {
  border-top: none;
}

.customTable > .body > .sectionConfigurations > .row > .rowItem {
  width: 25%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowItem > p {
  text-align: left;
  width: 100%;
  white-space: break-spaces;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue {
  width: 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  text-align: center;
  word-break: break-all;
  border-left: 1px solid var(--main-color);
}

.customTable > .body > .sectionConfigurations > .row > .rowValue > div {
  text-align: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue > div {
  padding: 5px 0;
}

.customTable > .body > .sectionConfigurations > .row:nth-child(odd) {
  background-color: rgba(215, 215, 215, 0.5);
}

.customTable > .body > .sectionConfigurations > .row:nth-child(even) {
  background-color: var(--neutral-color);
}

.unitsIfPriceRatio {
  font-size: 12px;
}

.medica-advanced-options__toggle{
  display: flex;
  align-items: center;
}

.medica-advanced-options__toggle label{
  margin-right: 1.5rem;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .scenarioInformationContent {
    padding: 3vh 4vw;
  }

  .customTable {
    margin-bottom: 0;
  }

  .customTable.smallTable {
    margin-top: 3vh;
  }

  .scenarioGraphContainer {
    min-height: 52vh;
    padding: 20px;
  }

  .customTable > .body {
    font-size: var(--xsmall);
  }

  .customTable > .body > .sectionConfigurations > .row > .rowItem > p {
    text-align: left;
  }

  .customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue > div {
    padding: 0;
  }

  .scenarioTableContainer {
    overflow-x: scroll;
  }

  .customTable.smallTable > .body > .sectionName > p {
    white-space: normal;
  }

  .unitsIfPriceRatio {
    font-size: 7px;
  }
}
