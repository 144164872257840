.centerContainer {
  display: flex;
  height: auto !important;
  width: 100%;
}

.loadingInformation {
  height: 40;
  margin: auto;
  min-width: 20%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #0379ba;
  margin: auto;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  width: 40px;
  animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

.popUpContainer {
  margin-left: 10px;
  display: inline-block;
}

.languageSelector {
  width: 100%;
  display: flex;
  margin: 5vh 0;
  padding: 0 5vh;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
