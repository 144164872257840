body {
  height: auto;
}

.displayFlex {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}
