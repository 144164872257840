.crisisBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.crisisBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}

.create-crisis-simulation__game-mode-help-list {
  text-align: justify;
}

.create-crisis-simulation__game-mode-help-list li:not(:last-child) { 
  margin-bottom: 10px; 
}

.crisisBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}
