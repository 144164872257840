.abogadosBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.abogadosBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}

.abogadosBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}
