.sectionVideoHeader {
  background-color: var(--neutral-color);
  max-width: 200px;
  margin: 0 10px;
  padding: 10px 25px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.videoFrameContainer {
  padding: 20px 25px;
  background: var(--neutral-color);
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.videoDescriptionContainer {
  padding: 10px 0;
}

.descriptionText {
  font-size: var(--small);
}

.videoOverlay {
  position: absolute;
  width: 80%;
  height: 55%;
  top: 10%;
  left: 10%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.videoOverlay:focus,
.videoOverlay:active {
  outline: none;
}

.youtubeIcon {
  opacity: 0.8;
}
