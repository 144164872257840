.ui.form.createNewScenarioForm {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.newScenarioItem {
  margin-bottom: 35px;
}

.newScenarioItem > .seedItem {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.newScenarioItem > .seedItem > .inputSection {
  width: 80%;
}

.newScenarioItem > .seedItem > .buttonSection {
  height: 46px;
}

.newScenarioItem > .seedItem > .buttonSection.heigthGrow {
  height: 82px;
}

.newScenarioItem > .seedItem > .buttonSection > button {
  color: var(--main-color);
  background-color: var(--neutral-color);
  border: none;
  outline: none;
  padding: 10px;
  height: 100%;
}

.newScenarioItem > .seedItem > .buttonSection > button:hover {
  background-color: var(--iconBkg-color);
}

.ui.form.createNewScenarioForm > .submitButtonContainer {
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .newScenarioItem {
    margin-bottom: 20px;
  }

  .newScenarioItem > .seedItem {
    flex-direction: column;
  }

  .newScenarioItem > .seedItem > .inputSection {
    width: 100%;
  }

  .newScenarioItem > .seedItem > .buttonSection {
    width: 100%;
  }

  .newScenarioItem > .seedItem > .buttonSection > button {
    height: 30px;
    width: 100%;
    font-size: var(--small);
    background-color: var(--iconBkg-color);
  }
}
