.questionnaireForm {
  text-align: center;
  max-width: 400px;
  margin: 5% auto 0 auto;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .questionnaireForm {
    width: 100%;
  }
}
