.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.ui.image.creambowDetailLogo {
  height: 100px;
  width: 100px;
  background: #FAF9F9;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .detailsContainer > .simulationIcon > .medicaLogo {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }

  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}
