.ui.table {
  border: none;
  background-color: transparent;
}

.ui.table tfoot th div {
  background-color: transparent;
}

.ui.table tbody {
  background-color: var(--neutral-color);
}

.ui.table tbody tr {
  border: 1px solid var(--main-color) !important;
  cursor: pointer;
}

.ui.table thead tr th {
  font-size: var(--normal) !important;
  border-radius: 0;
  color: var(--neutral-color) !important;
  height: 42px !important;
  background-color: var(--main-color) !important;
}

.ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  border-left: none;
}

.ui.table tbody tr td {
  border: 1px solid var(--main-color) !important;
  cursor: pointer;
  letter-spacing: 0.5px !important;
  font-size: var(--normal) !important;
}

.ui.selectable.table tbody tr:hover {
  color: var(--main-color) !important;
}

.ui.table tbody tr:nth-child(odd) {
  background-color: var(--neutral-color);
}
.ui.table tbody tr:nth-child(even) {
  background-color: rgba(215, 215, 215, 0.5);
}

.ui.table tbody tr:nth-child(odd):hover {
  background-color: var(--neutral-color) !important;
}
.ui.table tbody tr:nth-child(even):hover {
  background-color: rgba(215, 215, 215, 0.5) !important;
}

.ui.menu {
  border: none !important;
  box-shadow: none !important;
}

.ui.menu .item:before {
  width: 0;
}

.ui.sortable.table thead th.ascending:after {
  content: none !important;
}

.ui.sortable.table thead th.descending:after {
  content: none !important;
}

.ui.table tfoot tr th {
  background-color: transparent;
  padding: 0.78571429em 0;
}

.ui.table tr th:hover {
  color: rgba(215, 215, 215, 0.5) !important;
}

.ui.pagination.menu {
  border: none;
  box-shadow: none;
}

.ui.vertical.menu {
  border: none !important;
  box-shadow: none !important;
}

.ui.vertical.menu > .item {
  text-align: center;
}

.tableIcon {
  float: right !important;
}

.item {
  font-size: var(--normal);
}

a.item {
  font-weight: bold !important;
}

a.active.item {
  color: var(--main-color) !important;
  background-color: transparent !important;
}

.ui.modal {
  background-color: #fff;
  border-radius: 0 !important;
  height: auto !important;
  position: initial !important;
}

.ui.visible.visible.visible.visible.message {
  word-wrap: break-word;
}

.ui.container {
  min-height: 82vh;
}

.ui.grid.customGrid > .column.formColumn {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui.grid.customGrid > .column.formColumn.runAgainColumn {
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui.grid.customGrid > .column.formColumn > .form {
  width: 80%;
}

.ui.grid.customGrid > .column.formColumn.runAgainColumn > .form {
  width: 80%;
}

.ui.grid.customGrid > .column.logoColumn {
  padding: 0;
  border-radius: 30px;
  width: 200px;
  height: 200px;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ui.grid.customGrid > .column.logoColumn > .medicaLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background-color: #f9f8f8;
  border-radius: 30px;
}

.ui.grid.customGrid > .column.logoColumn > .simunationLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: rgba(126, 33, 126, 1);
  border-radius: 30px;
}

.crisisLogoContainer{
  width: 200px;
  height: 200px;
  border-radius: 30px;
  background: #18b1b9;
  display: flex;
}

.ui.image.crisisLogo {
  width: auto;
  height: 140px;
  margin: auto;
}

.ui.image.abogadosLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: #3a4149;
  border-radius: 30px;
}

.ui.image.creambowLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: #FAF9F9;
  border-radius: 30px;
}

.ui.container.scenarioInformation {
  min-height: 20vh;
  margin: 30px 0;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .ui.form .field {
    margin-bottom: 2vh !important;
  }

  .ui.grid.customGrid > .column.formColumn > .ui.form {
    width: 100%;
  }

  .ui.container.scenarioInformation {
    margin: 15px 0;
    padding: 0;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .ui.form .field {
    margin-bottom: 2vh !important;
  }

  .ui.grid.customGrid > .column.formColumn > .ui.form {
    width: 100%;
  }
}
