.homeMiddle {
  min-height: auto;
  margin: 5vh 0 0 0;
}

.headerCellIcon {
  size: "lg";
  color: "#FFF";
}

.detailButtons {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.headerCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tableContainer {
    overflow-x: scroll;
  }
}
