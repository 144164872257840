.loadingContainer {
  display: flex !important;
  height: 30vh !important;
  width: 100% !important;
}

.loadingInformation {
  height: 40px !important;
  margin: auto !important;
  min-width: 20px !important;
}

.loader {
  border: 4px solid #f3f3f3 !important;
  border-radius: 50% !important;
  border-top: 4px solid #0379ba !important;
  margin: 20px auto !important;
  height: 40px !important;
  width: 40px !important;
  animation: spin 1s linear infinite !important;
}

.sub.header {
  font-size: var(--large) !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
