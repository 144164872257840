.tableContainer > .profesorScenarioTable > tbody > tr > td.buttonCell > button.actionButton {
  background-color: transparent;
  padding: 8px 20px;
}

.tableContainer > .profesorScenarioTable > tbody > tr > td.buttonCell > button.actionButton:hover {
  color: var(--button-color);
  background-color: var(--iconBkg-color);
}

.tableContainer
  > .profesorScenarioTable
  > tbody
  > tr
  > td.buttonCell
  > button.actionButton:hover
  > .tableIcon.trashIcon
  > path {
  color: rgba(255, 0, 0, 0.7);
}

.scenarioManagerTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scenarioManagerInfoMessage {
  margin-top: 8vh;
}

.scenarioManagerTableTopper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.scenarioManagerIntroText {
  margin-bottom: 0px;
  width: 75%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: justify;
}

.scenarioManagerCreateScenarioButton {
  justify-self: end;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .scenarioManagerTitleContainer {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .scenarioManagerIntroText {
    width: 100%;
  }

  .scenarioManagerTitleContainer > .defaultButton {
    position: inherit;
  }
}
