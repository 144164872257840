.storeDownloadIcon {
  object-fit: scale-down;
  height: 4.3vh;
  width: auto;
  cursor: pointer;
}

.iconDownload {
  margin-top: 5vh;
}

.downloadTitle {
  margin-bottom: 2vh;
}

.downloadAppIconBackground {
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  padding: 2vh;
  background: #f9f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadLinkEmbeddedIcon.crisisDownloadAppLogo, 
.downloadAppIconBackground.crisisDownloadAppLogo {
  background: #18b1b9;
}

.downloadLinkEmbeddedIcon.supplyChainDownloadAppLogo,
.downloadAppIconBackground.supplyChainDownloadAppLogo {
  background: #006F5D;
}

.downloadLinkEmbeddedIcon.simunationDownloadAppLogo,
.downloadAppIconBackground.simunationDownloadAppLogo {
  background: rgb(126, 33, 126);
}

.downloadLinkEmbeddedIcon {
  height: 150px;
  width: 150px;
  border-radius: 30px;
  display: flex;
  margin:auto;
  align-items: center;
  justify-content: center;
}

.downloadAppGrid {
  height: 90vh;
}

.infoFrame {
  padding: 20px 25px;
  background: var(--neutral-color);
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.downloadAppInstructionsText {
  font-size: var(--xlarge);
  text-align: justify;
}

.windowsLink {
  margin-top: 6px;
  text-align: center;
  font-size: var(--medium);
  color: var(--button-color);
}

.windowsLink a {
  color: var(--main-color);
}

@media screen and (max-width: 767px) {
  .downloadColumn {
    margin-top: 0;
    min-height: 65vh;
  }

  .logoContainer > .logoBackground {
    height: 77px;
    width: 77px;
    border-radius: 20px;
  }

  .storeDownloadIcon {
    height: auto;
    cursor: pointer;
    width: 210px;
  }
}
