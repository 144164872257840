.ui.list.selection > .item.product-item__faq-products {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF50
}

.ui.list.selection.list > .item.product-item__faq-products:hover {
    background-color: hsla(0, 0%, 100%, 0.8);
    box-shadow: 3px 3px #BBBBBBCC;
}

.ui.list.selection > .item.product-item__faq-products > .content {
    display: inline-block;
    margin-left: 1rem;
    
}

.product-avatar__faq_products {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.item.product-item__faq-products > .product-avatar__faq_products  img.image {
    width: auto;
    height: auto;
    max-width: 50px;
    max-height: 50px;  
    display: inline-block !important;
}


.ui.list > .item.product-item__faq-products > i.icon {
    display: inline-block;
    margin: 0 5px
}