.profileFrame {
  display: flex;
  height: 50vh;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

.profileIconContainer {
  width: 40%;
  height: 100%;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  height: 65%;
}

.profileDetailsContainer {
  width: 58%;
  height: 60%;
  display: flex;
  align-items: center;
}

.profileDetailsContainer > .detailsData {
  min-width: 85%;
  min-height: 40vh;
  padding: 5vh;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.profileDetailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xxlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.profileDetailsContainer > .detailsData > .list > .item {
  font-size: var(--xlarge);
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.profileDetailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.profileDetailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 742px) and (orientation: portrait) {
  .profileDetailsContainer {
    margin-top: 25%;
  }

  .profileIconContainer {
    display: none;
  }

  .profileDetailsContainer {
    width: 100%;
  }

  .profileDetailsContainer > .detailsData {
    width: 100%;
  }

  .profileDetailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .profileDetailsContainer > .detailsData > .list > .item {
    font-size: var(--normal);
  }
}

@media only screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait) {
  .profileImage {
    height: 45%;
  }

  .profileDetailsContainer {
    width: 50%;
  }

  .profileIconContainer {
    width: 45%;
    height: 70%;
  }

  .profileImage {
    height: 50%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: portrait) {
  .profileImage {
    height: 50%;
  }

  .profileDetailsContainer {
    width: 60%;
  }

  .profileIconContainer {
    width: 50%;
    height: 70%;
  }

  .profileImage {
    height: 50%;
  }
}
