.cardFormContainer {
  padding: 0 150px;
}

.StripeElement {
  padding: 5px;
  width: 100%;
  box-shadow: none;
  background-color: var(--neutral-color);
  margin: auto;
  box-sizing: unset;
}

.StripeElement.StripeElement--empty {
  background-color: var(--neutral-color);
}

.StripeElement--focus {
  box-shadow: 0;
}

.StripeElement--invalid {
  border-color: var(--danger-color);
}

.centeredLabel {
  height: 18px;
  font-size: var(--large);
  line-height: 0.75;
  letter-spacing: 1.8px;
  text-align: center;
  margin-top: var(--normal);
}

.discountLabel {
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
  height: 18px;
  font-size: var(--normal);
  line-height: 0.75;
  letter-spacing: 1.8px;
  text-align: center;
  margin-top: var(--xlarge);
}

.discountLabel:hover {
  color: #002A61;
}

.column.breakdown {
  margin: 2vw;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .cardFormContainer {
    padding: 0;
  }

  .StripeElement {
    width: 100%;
    padding: 2px;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .cardFormContainer {
    padding: 0;
  }

  .StripeElement {
    width: 100%;
    padding: 2px;
  }
}
