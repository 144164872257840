.arrowWrapper {
  margin: 0 10px;
  width: 10px;
  cursor: pointer;
  margin-bottom: 10vh;
}

.arrowLeft {
  margin-right: -10px;
}

.arrowRight {
  margin-left: -10px;
}

.ui.card.product-card__catalogue {
  flex-direction: row;
}

.ui.card.product-card__catalogue .content {
  display: flex;
  flex-direction: column;
}

.ui.card.product-card__catalogue .bottomArea {
  width: unset;
  margin: unset;
  margin-top: auto;
  vertical-align: bottom;
  justify-self: flex-end;
  align-self: flex-end;
}

.product-row__catalogue{
  row-gap: 2rem;
}

.productWrapper {
  display: flex;  
  flex-direction: column;  
  align-items: center;
}

.ui.card .productImage {
  width: 10rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
  border-radius: .3rem 0 0 .3rem !important;
  position: relative;
  overflow: hidden;
}

.productLogo {
  height: 5.5rem;
}

.productName {
  font-size: var(--large);
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 0;
  padding: 15px 0 10px 0;
}

.coming-soon-badge__product {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 1rem;
  color: white;
  background: #ff5454;
  padding: 0.5rem;
  transform: rotateZ(45deg) translateX(3.5rem) translateY(-1rem);
  transform-origin: center;
  width: 12rem;
  text-align: center;
}

.product-card__catalogue .bottomArea{
  justify-content: flex-end;
}

.product-card__catalogue .bottomArea .ui.basic.button{
  font-size: 0.8rem !important;
  min-width: unset !important;
}

.product-card__catalogue--flavor-text{
  color: #777;
  text-align: justify;
  text-justify:auto;
  width: 100%;
}

.product-card__catalogue--topics-text{
  color: #777;
  font-size: 0.9rem;
  text-align: justify;
  text-justify:auto;
  width: 100%;
}


@media only screen and (max-width: 767px) {
  .arrowWrapper {
    margin-bottom: 12vh;
  }
  .ui.card .productImage {
    width: auto;
    height: 10rem;
    flex-shrink: unset;
    border-radius: unset;
  }
  .productLogo {
    height: 5rem;
  }
  .coming-soon-badge__product{
    font-size: 0.75rem;
    transform: rotateZ(45deg) translateX(2.5rem) translateY(-1rem);
    width: 9rem;
  }

  .ui.card.product-card__catalogue {
    flex-direction: column;
    min-height: unset;
  }
  
  .ui.card.product-card__catalogue .content {
    display: flex;
    flex-direction: column;
  }
  
  .ui.card.product-card__catalogue .bottomArea {
    margin-top: 7px;
    vertical-align: bottom;
    align-self: center;
  }

  .product-row__catalogue{
    row-gap: unset;
  }
}
