.dashBoardContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
}

.noDashboardContainer {
  padding: 0 20%;
}

.rightSideContainer {
  height: 100%;
  width: 84%;
  padding: 0 10% 0 2%;
  overflow-y: auto;
}

.leftSideContainer {
  height: 100%;
  width: 18%;
  padding-top: 10%;
}

.simulationRefresh {
  display: flex;
  align-items: center;
  padding: 20px 0% 20px 0%;
}

.simulationRefresh > p {
  font-size: var(--xlarge);
  margin: 0 20px 0 0;
}

.teamSelector {
  display: none;
  align-items: center;
}

.teamItemsContent {
  display: flex;
  flex-flow: wrap;
  max-height: 80px;
  overflow: auto;
  margin-left: 10px;
  background-color: var(--neutral-color);
}

.teamItem {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0 !important;
  color: var(--button-color);
  font-size: var(--normal);
  margin: 5px 10px;
  height: auto;
  min-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamItem > label {
  font-size: var(--normal);
  margin: 0 10px;
  width: auto;
  white-space: normal;
}

.teamItem > button {
  background: transparent;
  border: none;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openChartModalButton {
  height: 20px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.openChartModalButton:hover {
  background: var(--border-color);
}

.openChartModalButton:active {
  animation: controlledFade 0.4s;
  outline: none;
}

.closeChartModalButton {
  position: absolute;
  height: 40px;
  right: 6px;
  top: 8px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.closeChartModalButton:active {
  animation: controlledFade 0.4s;
  outline: none;
}

.sixteen.wide.column.sectionGraphHeaderCrisis {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.header.sectionGraphHeaderCrisis_title {
  background-color: var(--neutral-color);
  max-width: 400px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.header.sectionGraphHeaderCrisis_title > .icon{
  color: #0379ba;
  cursor: pointer;
  font-size: 16px;
  transition: all .2s linear;
}
.ui.header.sectionGraphHeaderCrisis_title > .icon__down{
  transform: rotate(180deg);
}

.chartContainer {
  padding-bottom: 25px;
  width: 190px;
  height: 180px;
}

.noShowGraphGrid {
  position: absolute !important;
  top: -9999px;
  left: -9999px;
}

.showGraphGrid {
  position: inherit;
  margin-bottom: 5vh;
}

.chartContainer > .chartjs-render-monitor {
  background-color: var(--neutral-color);
  border: 2px solid var(--button-color);
}

.teamsAsideContainer {
  height: 100%;
  padding: 10px 20px;
}

.teamsAsideListContainer {
  height: 90%;
  overflow-y: auto;
  padding-bottom: 100px;
}

.dashBoardTeamCheckbox {
  margin: 0 10px;
}

.dashBoardTeamsList {
  padding: 5%;
  background-color: #fff;
  border: 2px solid var(--button-color);
}

.ui.form.dashBoardTeamsList > .field {
  margin-bottom: 0.5em;
}

.dashBoardTeamItem {
  display: flex;
  align-items: center;
}

.dashBoardGrid {
  height: 100%;
}

.dashBoardListColumn {
  max-height: 100%;
  overflow-y: auto;
}

.dashBoardChartsColumn {
  padding-right: 14% !important;
  max-height: 100%;
  overflow-y: auto;
}

.teamListHeader {
  padding: 10px 0 0 0;
}

.teamListHeader > h3 {
  margin: 15px 0 10px 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.selectAllButton {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.specialSelection {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.selectAllButton:hover,
.specialSelection:hover {
  color: var(--button-color);
  background: var(--border-color);
  border: 1px solid var(--border-color);
}

.selectAllButton:active,
.specialSelection:active,
.specialSelection:focus {
  animation: controlledFade 0.4s;
  outline: none;
}

.disabled {
  color: var(--text-color-light);
}

.teamItem {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.teamItemLabel {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.dashBoardTeamItem > label {
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  word-break: break-word;
}

.specialGraphColumn > .chartContent {
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.specialGraphColumn > .chartContent > h5 {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 10px;
  padding: 10px 25px;
}

.scrollToButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  z-index: 1;
}

.dashboardLoadingContainer {
  height: 150px;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallGraph {
  height: 160px;
  width: calc(100% - 5px);
  position: relative;
  margin: 30px 20px 50px 0;
}

#bigGraph {
  height: 450px;
  width: calc(100% - 5px);
  margin: 20px;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 4%;
  left: 20%;
  width: auto;
  border-radius: 10px;
}

.tooltip > span {
  color: var(--neutral-color);
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip:first-child {
  padding-top: 5px;
}

.tooltip > span:last-child {
  padding-bottom: 10px;
}

.sectionSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0% 20px 0%;
  width: 100%;
}

.showMore {
  position: absolute;
  bottom: 40px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
}

.addMoreCharts {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--button-color);
  color: var(--neutral-color);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  font-size: 25px;
}

.titleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.buttonGraphGroup {
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}

.selectGraphButton {
  background: var(--neutral-color);
  border: none;
  color: var(--button-color);
  font-size: var(--normal);
  padding: 5px 8px;
  height: 35px;
}

.selectGraphButton.selectedGraph {
  background: rgba(3, 121, 186, 0.1);
  font-weight: bold;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.buttonLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px 0 1px 1px;
  border-color: var(--button-color);
  border-style: solid;
}

.buttonRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px 1px 1px 0;
  border-color: var(--button-color);
  border-style: solid;
}

.selectGraphButton:hover {
  color: var(--button-color);
  background: var(--border-color);
}

.selectGraphButton:focus {
  outline: none;
}

.tryAgainContent {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

@keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .titleSection {
    display: grid;
    margin-bottom: 20px;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 440px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }

  .dashBoardGrid {
    height: 100%;
  }

  .dashBoardContainer {
    padding-left: 0;
  }

  .noDashboardContainer {
    padding: 5%;
  }

  .dashBoardChartsColumn {
    padding: 0;
  }

  .simulationRefresh {
    padding: 14px 0 14px 0;
  }

  .smallGraph {
    margin: 30px 5px 30px 5px;
  }

  .openChartModalButton {
    right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .teamSelector {
    display: flex;
  }
}
