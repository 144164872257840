@import url(https://fonts.googleapis.com/css2?family=Chivo:wght@400;700&display=swap);
* {
  font-family: "Chivo", sans-serif, Icons !important;
}

/* VARIABLES */
:root {
  /* COLORS */
  --main-color: #0379ba;
  --text-color-light: #494848;
  --danger-color: #912d2b;
  --background-color: #f9f8f8;
  --button-color: #272727;
  --button-detail-color: #494848;
  --border-color: #d7d7d7;
  --neutral-color: #fff;
  --black-color: #000;
  --iconBkg-color: #efefef;
  /* FONT-SIZES */
  --xxsmall: 7px;
  --xsmall: 8px;
  --small: 11px;
  --normal: 14px;
  --large: 16px;
  --xlarge: 19px;
  --xxlarge: 22px;
  --xxxlarge: 25px;
  --bigger: 49px;
}

body {
  margin: 0;
  padding: 0;
}

.loginButtons {
  position: absolute;
  top: 8%;
  width: 15%;
  right: 5%;
}

.versionLabel {
  color: #912d2b;
  color: var(--danger-color);
  padding: 5px 10px;
  text-align: right;
  width: 100%;
}

.appBackground {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .loginButtons {
    top: 5%;
    right: 15%;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .loginButtons {
    top: 14%;
    right: 10%;
  }
}

.ui.table {
  border: none;
  background-color: transparent;
}

.ui.table tfoot th div {
  background-color: transparent;
}

.ui.table tbody {
  background-color: var(--neutral-color);
}

.ui.table tbody tr {
  border: 1px solid var(--main-color) !important;
  cursor: pointer;
}

.ui.table thead tr th {
  font-size: var(--normal) !important;
  border-radius: 0;
  color: var(--neutral-color) !important;
  height: 42px !important;
  background-color: var(--main-color) !important;
}

.ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  border-left: none;
}

.ui.table tbody tr td {
  border: 1px solid var(--main-color) !important;
  cursor: pointer;
  letter-spacing: 0.5px !important;
  font-size: var(--normal) !important;
}

.ui.selectable.table tbody tr:hover {
  color: var(--main-color) !important;
}

.ui.table tbody tr:nth-child(odd) {
  background-color: var(--neutral-color);
}
.ui.table tbody tr:nth-child(even) {
  background-color: rgba(215, 215, 215, 0.5);
}

.ui.table tbody tr:nth-child(odd):hover {
  background-color: var(--neutral-color) !important;
}
.ui.table tbody tr:nth-child(even):hover {
  background-color: rgba(215, 215, 215, 0.5) !important;
}

.ui.menu {
  border: none !important;
  box-shadow: none !important;
}

.ui.menu .item:before {
  width: 0;
}

.ui.sortable.table thead th.ascending:after {
  content: none !important;
}

.ui.sortable.table thead th.descending:after {
  content: none !important;
}

.ui.table tfoot tr th {
  background-color: transparent;
  padding: 0.78571429em 0;
}

.ui.table tr th:hover {
  color: rgba(215, 215, 215, 0.5) !important;
}

.ui.pagination.menu {
  border: none;
  box-shadow: none;
}

.ui.vertical.menu {
  border: none !important;
  box-shadow: none !important;
}

.ui.vertical.menu > .item {
  text-align: center;
}

.tableIcon {
  float: right !important;
}

.item {
  font-size: var(--normal);
}

a.item {
  font-weight: bold !important;
}

a.active.item {
  color: var(--main-color) !important;
  background-color: transparent !important;
}

.ui.modal {
  background-color: #fff;
  border-radius: 0 !important;
  height: auto !important;
  position: static !important;
  position: initial !important;
}

.ui.visible.visible.visible.visible.message {
  word-wrap: break-word;
}

.ui.container {
  min-height: 82vh;
}

.ui.grid.customGrid > .column.formColumn {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui.grid.customGrid > .column.formColumn.runAgainColumn {
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui.grid.customGrid > .column.formColumn > .form {
  width: 80%;
}

.ui.grid.customGrid > .column.formColumn.runAgainColumn > .form {
  width: 80%;
}

.ui.grid.customGrid > .column.logoColumn {
  padding: 0;
  border-radius: 30px;
  width: 200px;
  height: 200px;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ui.grid.customGrid > .column.logoColumn > .medicaLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background-color: #f9f8f8;
  border-radius: 30px;
}

.ui.grid.customGrid > .column.logoColumn > .simunationLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: rgba(126, 33, 126, 1);
  border-radius: 30px;
}

.crisisLogoContainer{
  width: 200px;
  height: 200px;
  border-radius: 30px;
  background: #18b1b9;
  display: flex;
}

.ui.image.crisisLogo {
  width: auto;
  height: 140px;
  margin: auto;
}

.ui.image.abogadosLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: #3a4149;
  border-radius: 30px;
}

.ui.image.creambowLogo {
  width: 200px;
  height: 200px;
  padding: 20px;
  background: #FAF9F9;
  border-radius: 30px;
}

.ui.container.scenarioInformation {
  min-height: 20vh;
  margin: 30px 0;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .ui.form .field {
    margin-bottom: 2vh !important;
  }

  .ui.grid.customGrid > .column.formColumn > .ui.form {
    width: 100%;
  }

  .ui.container.scenarioInformation {
    margin: 15px 0;
    padding: 0;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .ui.form .field {
    margin-bottom: 2vh !important;
  }

  .ui.grid.customGrid > .column.formColumn > .ui.form {
    width: 100%;
  }
}

body {
  height: auto;
}

.displayFlex {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}

.centerContainer {
  display: flex;
  height: auto !important;
  width: 100%;
}

.loadingInformation {
  height: 40;
  margin: auto;
  min-width: 20%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #0379ba;
  margin: auto;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  width: 40px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

.popUpContainer {
  margin-left: 10px;
  display: inline-block;
}

.languageSelector {
  width: 100%;
  display: flex;
  margin: 5vh 0;
  padding: 0 5vh;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ui.modal > .header:not(.ui) {
  font-size: var(--xxxlarge);
  font-weight: bold;
  line-height: 0.96;
  color: #000000;
}

.ui.modal > .actions {
  background: #fff;
  text-align: center !important;
}

.ui.modal > .actions > .button {
  background-color: transparent !important;
  border: 2.5px solid #272727 !important;
  border-radius: 0 !important;
  color: #272727 !important;
  font-size: var(--normal) !important;
}

.ui.modal > .actions > .button:hover {
  background-color: #272727 !important;
  color: #fff !important;
}

.ui.form .field > .selection.dropdown > .dropdown.icon {
  float: right;
  display: none;
}

.ui.selection.dropdown {
  border: none;
  font-size: var(--xlarge);
  color: var(--button-color);
  min-height: 0;
  min-width: 30%;
  padding: 10px 0;
  text-align: center;
}

.ui.selection.dropdown .menu > .item {
  padding: 10px 0 !important;
  text-align: center;
}

.ui.selection.dropdown > .dropdown.icon {
  display: none;
}

.columnModal {
  float: left;
  width: 33.33%;
  padding: 10px;
  text-align: center;
}

.columnContent {
  text-align: center;
}

.columnTitle {
  text-align: center;
  font-size: var(--large);
  font-weight: 800;
}

.columnText {
  text-align: center;
  font-size: var(--large);
}

.messageSeparator {
  margin: 15px 0 0 0;
}

.contentAdvanceModal {
  content: "";
  display: table;
  clear: both;
  font-size: 1.28571429rem;
  width: 100%;
}

.advancedOptionsDescriptionLabel {
  margin-bottom: 20px;
}

.advancedOptionsLabelWrapper {
  padding: 0;
}

.advancedOptionsRadioNameLabel {
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  min-height: 20px;
  align-items: center;
}

.advancedOptionsRadioDescriptionLabel {
  margin: 0;
  color: #5d5d5d;
}

.advancedOptionsRecommendedSpan {
  display: flex;
}

.advancedOptionsRecommendedIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 5px;
}

.modalHeaderItem > label {
  margin: 5px 0;
  font-size: var(--normal) !important;
  color: var(--main-color) !important;
}

.modalTeamItem > label {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.modalTeamHeader {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.itemsDocumentContent {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin: 15px 0;
}

.itemDocument {
  text-align: center;
  cursor: auto;
  width: 30%;
  margin: 20px 5px;
}

.itemDocumentIcon {
  width: 100%;
}

.itemDocumentLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graphHint {
  background-color: var(--border-color);
  border-radius: 5px;
}

.graphHint > .hintTitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.graphHint > .hintTitle > h3 {
  font-size: var(--normal);
  color: var(--neutral-color);
  margin: 0;
  padding: 5px 10px 0 10px;
}

.graphHint > p {
  font-size: var(--small);
  color: var(--neutral-color);
  margin: 0;
  padding: 0 10px;
}

.graphHint > p:last-child {
  padding-bottom: 5px;
}

.modalVideoDescription {
  color: var(--text-color-light);
  padding: 0 10px 10px 10px;
}

.ui.modal > .modalVideoContent {
  padding: 1.5rem 0 1.5rem 0;
}

.modalVideoButton {
  color: var(--main-color);
  font-size: var(--normal);
  background-color: var(--neutral-color);
  border: none;
  height: 36px;
}

.modalVideoButton:hover {
  background-color: #eee;
}

.modalVideoButton:active,
.modalVideoButton:focus {
  outline: none;
}

.modifyModalError {
  padding: 20px 0 0 0;
}

.instructtionsModal.ui.modal > .content > .ui.list > li {
  font-size: var(--large);
}

.descriptionInput {
  margin: 20px 0;
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer {
  display: flex;
}

.ui.modal.createNewScenarioModal > .content > .createNewScenarioConfirmationText {
  font-size: var(--large);
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemName {
  width: 55%;
  font-size: var(--normal);
  white-space: pre-wrap;
  padding: 5px;
}
.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemValue {
  width: 45%;
  font-size: var(--normal);
  white-space: pre-wrap;
  padding: 5px;
  font-weight: bold;
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemValue > strong {
  overflow-wrap: break-word;
}

.contentMarginTop {
  margin-top: 1.5rem;
}

.contentMarginBottom {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 742px) {
  .inputContent {
    height: 42px;
  }

  .modalInputContent > .inputItem > .ui input {
    font-size: var(--small) !important;
  }

  .ui.selection.dropdown {
    min-width: 10vw !important;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .ui.modal > .modalVideoContent {
    padding: 1.5rem 0 1.5rem 0 !important;
  }

  .ui.modal > .modalVideoContent > iframe {
    height: 250px;
  }

  .modalInputContent {
    height: 36px;
  }
}

.bottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.bottomArea > .goBackButton {
  background-color: var(--neutral-color);
  border: 1px solid;
  border-radius: 0;
  color: var(--main-color);
  font-size: var(--normal);
  min-width: 135px;
}
.bottomArea > .goBackButton:hover {
  background-color: #eee;
  border-radius: 0;
  color: var(--main-color);
}

.noButtonArea > .goBackButton {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color);
  border-radius: 0;
  color: var(--button-color);
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}
.noButtonArea > .goBackButton:hover {
  background-color: var(--button-color);
  color: var(--neutral-color);
}

.bottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
}

.bottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.productColumn > .bottomArea > .ui.basic.button {
  min-width: 90% !important;
  position: absolute;
  bottom: 0;
}

.logoutButton > .ui.basic.button {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
  height: 44px;
}

.logoutButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.defaultButton > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color);
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
  margin: 0px;
}

.defaultButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.dropDownContainer {
  border: 2px solid #d7d7d7;
  height: 46px;
  width: 100%;
  background-color: var(--neutral-color);
}

.dropDownContainer > .ui.dropdown.customDropdown {
  align-items: center;
  border: none;
  display: flex;
  font-size: var(--xlarge);
  justify-content: space-between;
  color: var(--button-color);
  padding: 10px;
  height: 100%;
  width: 100%;
}

.customHeader {
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  margin: 10px 0;
}
.customHeader > .headerLeft {
  align-items: center;
  display: flex;
  width: 20%;
  height: 100%;
}
.customHeader > .headerRight {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 80%;
}
.headerRight > .ui.dropdown > .dropdown.icon {
  display: none !important;
}

.dropDownItem {
  background-color: var(--background-color);
}

.dropDownItem > a {
  color: var(--button-color);
}

.dropDownItem > a:active,
.dropDownItem > a:focus,
.dropDownItem > a:hover {
  background-color: var(--button-detail-color) !important;
  color: var(--neutral-color) !important;
}

.headerRight > .onlyMobile {
  display: none;
}

.headerRight > .headerButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5% 0;
}

.headerRight > .headerButtonsContainer > .navLinkContainer {
  margin: 0 0 0 5%;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a {
  font-size: var(--large);
  background-color: var(---button-detail-color);
  border: none;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  color: var(--button-color);
  text-align: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a > .ui.label.headerLabel {
  font-size: var(--xsmall);
  background-color: var(--main-color);
  color: var(--neutral-color);
  font-weight: bold;
  top: -2em;
  padding: 5px;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a:focus,
.headerRight > .headerButtonsContainer > .navLinkContainer > a:active {
  outline: none;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a:hover {
  color: var(--main-color);
}

.headerRight > .dropdownHeader > .menu > .item.dropDownItem a > .ui.label.headerLabel {
  font-size: var(--small);
  background-color: var(--main-color);
  color: var(--neutral-color);
  position: absolute;
  top: 0;
  padding: 2.5px;
  font-weight: bold;
}

.selectedPath {
  color: var(--main-color);
}

.headerRight > .studentActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerRight > .studentActions > .languageSelector {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .headerRight > .onlyMobile {
    display: block;
  }

  .headerRight > .hideButtonsContainer {
    display: none;
  }

  .headerRight > .languageSelector {
    width: 60%;
    display: flex;
    margin: 2vh 0;
    padding: 0;
  }

  .customHeader > .headerRight {
    flex-direction: column-reverse;
    padding: 10px;
    align-items: flex-end;
  }

  .headerRight > .studentActions {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .headerRight > .studentActions > .languageSelector {
    margin: 10px 0 0 0;
    padding: 0;
  }

  .headerRight > .studentActions > .languageSelector > .field {
    width: 98%;
  }
}

.titleContainer {
  display: block;
  margin: 10px 0;
  min-height: 40px;
  text-align: center;
  width: 100%;
}

.titleTextContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleContainer > .titleTextContent > .title,
.titleContainer > .subtitle {
  font-size: var(--xxxlarge);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
}

.titleContainer > .title {
  text-transform: none;
}

.titleContainer > .reducedSubTitle {
  font-weight: 400;
  font-size: var(--xlarge);
}

.blackLine {
  background-color: #010101;
  height: 2px;
  margin-bottom: 21px;
  width: 52px;
  display: inline-table;
}

@media only screen and (max-width: 742px) {
  .titleContainer {
    margin: 2vh 0;
  }

  .titleContainer > .title {
    font-size: var(--xxlarge);
  }

  .titleContainer > .reducedSubTitle {
    font-size: var(--large);
  }
}

.arrowWrapper {
  margin: 0 10px;
  width: 10px;
  cursor: pointer;
  margin-bottom: 10vh;
}

.arrowLeft {
  margin-right: -10px;
}

.arrowRight {
  margin-left: -10px;
}

.ui.card.product-card__catalogue {
  flex-direction: row;
}

.ui.card.product-card__catalogue .content {
  display: flex;
  flex-direction: column;
}

.ui.card.product-card__catalogue .bottomArea {
  width: unset;
  margin: unset;
  margin-top: auto;
  vertical-align: bottom;
  justify-self: flex-end;
  align-self: flex-end;
}

.product-row__catalogue{
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.productWrapper {
  display: flex;  
  flex-direction: column;  
  align-items: center;
}

.ui.card .productImage {
  width: 10rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
  border-radius: .3rem 0 0 .3rem !important;
  position: relative;
  overflow: hidden;
}

.productLogo {
  height: 5.5rem;
}

.productName {
  font-size: var(--large);
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 0;
  padding: 15px 0 10px 0;
}

.coming-soon-badge__product {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 1rem;
  color: white;
  background: #ff5454;
  padding: 0.5rem;
  transform: rotateZ(45deg) translateX(3.5rem) translateY(-1rem);
  transform-origin: center;
  width: 12rem;
  text-align: center;
}

.product-card__catalogue .bottomArea{
  justify-content: flex-end;
}

.product-card__catalogue .bottomArea .ui.basic.button{
  font-size: 0.8rem !important;
  min-width: unset !important;
}

.product-card__catalogue--flavor-text{
  color: #777;
  text-align: justify;
  text-justify:auto;
  width: 100%;
}

.product-card__catalogue--topics-text{
  color: #777;
  font-size: 0.9rem;
  text-align: justify;
  text-justify:auto;
  width: 100%;
}


@media only screen and (max-width: 767px) {
  .arrowWrapper {
    margin-bottom: 12vh;
  }
  .ui.card .productImage {
    width: auto;
    height: 10rem;
    flex-shrink: unset;
    border-radius: unset;
  }
  .productLogo {
    height: 5rem;
  }
  .coming-soon-badge__product{
    font-size: 0.75rem;
    transform: rotateZ(45deg) translateX(2.5rem) translateY(-1rem);
    width: 9rem;
  }

  .ui.card.product-card__catalogue {
    flex-direction: column;
    min-height: unset;
  }
  
  .ui.card.product-card__catalogue .content {
    display: flex;
    flex-direction: column;
  }
  
  .ui.card.product-card__catalogue .bottomArea {
    margin-top: 7px;
    vertical-align: bottom;
    align-self: center;
  }

  .product-row__catalogue{
    grid-row-gap: unset;
    row-gap: unset;
  }
}

.inputContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  height: 46px;
  margin: 0;
  background-color: var(--neutral-color);
  width: 100%;
}

.labelContainer {
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: center;
}

.labelContainer.leftLabelAlignment {
  justify-content: left;
}

.specialLabel {
  font-size: var(--large);
  font-weight: 400;
  letter-spacing: 1.5px;
  padding: 8px 0;
}

.inputContainer > .ui.input.customInput {
  width: 100%;
  height: 100%;
}

.inputContainer > .ui.input.customInput > input {
  border: none;
  border-radius: 0;
  font-size: var(--xlarge);
  color: var(--button-color);
  height: 100%;
  width: 100%;
  padding: 10px;
  cursor: text;
}

.inputContainer.boldContent > .ui.input.customInput > input {
  font-weight: bold;
  font-size: var(--xxxlarge);
  color: var(--button-color);
  opacity: 1;
}

.inputContainer.errorIndicator {
  border: 2px solid rgba(159, 58, 56, 0.5);
}

.errorMessage {
  background-color: #9f3a38;
  color: #fff;
  font-size: var(--xxx-small);
  margin: 20px;
  padding: 5px;
  white-space: nowrap;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.numberIndicators {
  float: right;
  color: #b0adad;
}

.numberIndicators.errorCounter {
  color: #912d2b;
  font-weight: bold;
}

.textAreaContainer {
  border: 2px solid var(--border-color);
}

.textAreaContainer.errorIndicator {
  border: 2px solid rgba(159, 58, 56, 0.5);
}

.textAreaContainer > textarea.customTextArea {
  border: none;
  border-radius: 0;
  font-size: var(--xxlarge);
  color: var(--button-color);
  height: 100%;
  width: 100%;
  padding: 10px;
  resize: none;
}

.dateTimeSelectorContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  height: 45px;
  justify-content: center;
}

.dateTimeSelectorContainer > .inputItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--neutral-color);
  height: 100%;
  width: 100%;
}

.dateTimeSelectorContainer > .inputItem.dateInfo {
  width: 65%;
}

.dateTimeSelectorContainer > .inputItem.dateInfo > span {
  color: var(--button-color);
  cursor: pointer;
  font-size: var(--normal);
  letter-spacing: 2.2px;
  padding: 8px 5px;
}

.dateTimeSelectorContainer > .inputItem.timeSelector {
  width: 35%;
  padding: 0 10px;
}

.dateTimeSelectorContainer > .inputItem.timeSelector > .selector.dropdown {
  font-size: var(--normal);
  width: 50%;
}

.dateTimeSelectorContainer > .inputItem > img {
  height: 1.4em;
}

.inputSliderContainer {
  padding: 10px 0;
}

.sliderLabelContainer {
  margin: 10px 0;
}

.sliderLabelContainer > .labelContainer {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: left;
}

.sliderLabelContainer > .labelContainer.heightGrow > .specialLabel {
  width: 65%;
}

.sliderLabelContainer > .sliderControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sliderLabelContainer > .sliderControls > .buttonControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sliderLabelContainer > .sliderControls > .buttonControl > button {
  border: none;
  outline: none;
  height: 25px;
  width: 35px;
  background-color: var(--background-color);
}

.sliderLabelContainer > .sliderControls > .buttonControl > button:hover {
  border: 1px solid var(--main-color);
  background: var(--iconBkg-color);
}

.sliderLabelContainer > .sliderControls > p {
  font-size: var(--xxxlarge);
  margin: 0;
}

.inputSliderContainer > input[type="range"].inputSlider {
  height: 5px;
  -webkit-appearance: none;
  margin: 5px 0;
  width: 100%;
}

.inputSliderContainer > input[type="range"].inputSlider:focus {
  outline: none;
}

.inputSliderContainer > input[type="range"].inputSlider::-webkit-slider-runnable-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  background: var(--iconBkg-color);
  border-radius: 0px;
  border: 0px solid var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-webkit-slider-runnable-track {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-moz-range-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  background: var(--iconBkg-color);
  border-radius: 0px;
  border: 0px solid var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-moz-range-thumb {
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
}

.inputSliderContainer > input[type="range"]:focus::-moz-range-track {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-fill-lower {
  background: var(--iconBkg-color);
  border: 0px solid var(--iconBkg-color);
  border-radius: 0px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-fill-upper {
  background: var(--iconBkg-color);
  border: 0px solid var(--iconBkg-color);
  border-radius: 0px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-ms-fill-lower {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-ms-fill-upper {
  background: var(--border-color);
}

.checkInputContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  height: 46px;
  margin: 0;
  background-color: var(--neutral-color);
  width: 100%;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label {
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label::before {
  border: 2px solid var(--border-color);
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label::after {
  color: var(--main-color);
  height: 25px;
  width: 25px;
  font-size: 25px;
  padding: 3px 0;
}

.checkInputContainer > .ui.checkbox.customCheckInput input:checked:focus ~ label:before {
  border: 2px solid var(--main-color);
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput input:checked:focus ~ label:after {
  color: var(--main-color);
}

.toggleSelection {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  margin: auto;
}

.toggleSelection > label {
  margin-right: 10px;
}

.inputToggleContainer {
  margin: 10px 0;
  height: 46px;
}

.inputToggleContainer > .labelContainer {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-start;
}

.inputToggleContainer > .labelContainer > .toggle {
  margin-left: 20px;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .sliderLabelContainer > .labelContainer.heightGrow {
    width: 100%;
    height: 50px;
    display: inline-block;
  }

  .sliderLabelContainer > .labelContainer.heightGrow > .toggleSelection {
    width: 100%;
    position: static;
    position: initial;
    height: 100%;
    justify-content: center;
  }

  .sliderLabelContainer > .labelContainer > .specialLabel {
    width: 100%;
  }

  .sliderLabelContainer > .sliderControls > .buttonControl > p {
    padding-top: 10px;
    font-size: var(--small);
  }

  .sliderLabelContainer > .sliderControls > p {
    font-size: var(--normal);
  }
}

.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.ui.image.abogadosDetailLogo {
  height: 100px;
  width: 100px;
  background: #3a4149;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .detailsContainer > .simulationIcon > .medicaLogo {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }

  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}

.ui.table thead tr th.team-members-table__header-cell {
  background-color: #53a6d8 !important;
  padding: 0;
  height: 32px !important;
}

.ui.form.createNewScenarioForm {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.newScenarioItem {
  margin-bottom: 35px;
}

.newScenarioItem > .seedItem {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.newScenarioItem > .seedItem > .inputSection {
  width: 80%;
}

.newScenarioItem > .seedItem > .buttonSection {
  height: 46px;
}

.newScenarioItem > .seedItem > .buttonSection.heigthGrow {
  height: 82px;
}

.newScenarioItem > .seedItem > .buttonSection > button {
  color: var(--main-color);
  background-color: var(--neutral-color);
  border: none;
  outline: none;
  padding: 10px;
  height: 100%;
}

.newScenarioItem > .seedItem > .buttonSection > button:hover {
  background-color: var(--iconBkg-color);
}

.ui.form.createNewScenarioForm > .submitButtonContainer {
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .newScenarioItem {
    margin-bottom: 20px;
  }

  .newScenarioItem > .seedItem {
    flex-direction: column;
  }

  .newScenarioItem > .seedItem > .inputSection {
    width: 100%;
  }

  .newScenarioItem > .seedItem > .buttonSection {
    width: 100%;
  }

  .newScenarioItem > .seedItem > .buttonSection > button {
    height: 30px;
    width: 100%;
    font-size: var(--small);
    background-color: var(--iconBkg-color);
  }
}

.dashBoardContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
}

.noDashboardContainer {
  padding: 0 20%;
}

.rightSideContainer {
  height: 100%;
  width: 84%;
  padding: 0 10% 0 2%;
  overflow-y: auto;
}

.leftSideContainer {
  height: 100%;
  width: 18%;
  padding-top: 10%;
}

.simulationRefresh {
  display: flex;
  align-items: center;
  padding: 20px 0% 20px 0%;
}

.simulationRefresh > p {
  font-size: var(--xlarge);
  margin: 0 20px 0 0;
}

.teamSelector {
  display: none;
  align-items: center;
}

.teamItemsContent {
  display: flex;
  flex-flow: wrap;
  max-height: 80px;
  overflow: auto;
  margin-left: 10px;
  background-color: var(--neutral-color);
}

.teamItem {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0 !important;
  color: var(--button-color);
  font-size: var(--normal);
  margin: 5px 10px;
  height: auto;
  min-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamItem > label {
  font-size: var(--normal);
  margin: 0 10px;
  width: auto;
  white-space: normal;
}

.teamItem > button {
  background: transparent;
  border: none;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openChartModalButton {
  height: 20px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.openChartModalButton:hover {
  background: var(--border-color);
}

.openChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.closeChartModalButton {
  position: absolute;
  height: 40px;
  right: 6px;
  top: 8px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.closeChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.ui.grid > .column > div > .column > .sectionGraphHeaderCrisis > h3 {
  background-color: var(--neutral-color);
  max-width: 350px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.chartContainer {
  padding-bottom: 25px;
  width: 190px;
  height: 180px;
}

.noShowGraphGrid {
  position: absolute !important;
  top: -9999px;
  left: -9999px;
}

.showGraphGrid {
  position: inherit;
  margin-bottom: 5vh;
}

.chartContainer > .chartjs-render-monitor {
  background-color: var(--neutral-color);
  border: 2px solid var(--button-color);
}

.teamsAsideContainer {
  height: 100%;
  padding: 10px 20px;
}

.teamsAsideListContainer {
  height: 90%;
  overflow-y: auto;
  padding-bottom: 100px;
}

.dashBoardTeamCheckbox {
  margin: 0 10px;
}

.dashBoardTeamsList {
  padding: 5%;
  background-color: #fff;
  border: 2px solid var(--button-color);
}

.ui.form.dashBoardTeamsList > .field {
  margin-bottom: 0.5em;
}

.dashBoardTeamItem {
  display: flex;
  align-items: center;
}

.dashBoardGrid {
  height: 100%;
}

.dashBoardListColumn {
  max-height: 100%;
  overflow-y: auto;
}

.dashBoardChartsColumn {
  padding-right: 14% !important;
  max-height: 100%;
  overflow-y: auto;
}

.teamListHeader {
  padding: 10px 0 0 0;
}

.teamListHeader > h3 {
  margin: 15px 0 10px 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.selectAllButton {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.specialSelection {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.selectAllButton:hover,
.specialSelection:hover {
  color: var(--button-color);
  background: var(--border-color);
  border: 1px solid var(--border-color);
}

.selectAllButton:active,
.specialSelection:active,
.specialSelection:focus {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.disabled {
  color: var(--text-color-light);
}

.teamItem {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.teamItemLabel {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.dashBoardTeamItem > label {
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  word-break: break-word;
}

.specialGraphColumn > .chartContent {
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.specialGraphColumn > .chartContent > h5 {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 10px;
  padding: 10px 25px;
}

.scrollToButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  z-index: 1;
}

.dashboardLoadingContainer {
  height: 150px;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallGraph {
  height: 160px;
  width: calc(100% - 5px);
  position: relative;
  margin: 30px 20px 50px 0;
}

#bigGraph {
  height: 450px;
  width: calc(100% - 5px);
  margin: 20px;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 4%;
  left: 20%;
  width: auto;
  border-radius: 10px;
}

.tooltip > span {
  color: var(--neutral-color);
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip:first-child {
  padding-top: 5px;
}

.tooltip > span:last-child {
  padding-bottom: 10px;
}

.sectionSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0% 20px 0%;
  width: 100%;
}

.showMore {
  position: absolute;
  bottom: 40px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
}

.addMoreCharts {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--button-color);
  color: var(--neutral-color);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  font-size: 25px;
}

.titleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.buttonGraphGroup {
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectGraphButton {
  background: var(--neutral-color);
  border: none;
  color: var(--button-color);
  font-size: var(--normal);
  padding: 5px 8px;
  height: 35px;
}

.selectGraphButton.selectedGraph {
  background: rgba(3, 121, 186, 0.1);
  font-weight: bold;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.buttonLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px 0 1px 1px;
  border-color: var(--button-color);
  border-style: solid;
}

.buttonRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px 1px 1px 0;
  border-color: var(--button-color);
  border-style: solid;
}

.selectGraphButton:hover {
  color: var(--button-color);
  background: var(--border-color);
}

.selectGraphButton:focus {
  outline: none;
}

.tryAgainContent {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .titleSection {
    display: grid;
    margin-bottom: 20px;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 440px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }

  .dashBoardGrid {
    height: 100%;
  }

  .dashBoardContainer {
    padding-left: 0;
  }

  .noDashboardContainer {
    padding: 5%;
  }

  .dashBoardChartsColumn {
    padding: 0;
  }

  .simulationRefresh {
    padding: 14px 0 14px 0;
  }

  .smallGraph {
    margin: 30px 5px 30px 5px;
  }

  .openChartModalButton {
    right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .teamSelector {
    display: flex;
  }
}

.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.detailsContainer > .crisisSimulationIcon {
  height: 100px;
  width: 100px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 22px;
  display: flex;
  background-color: #18b1b9;
  justify-content: center;
}

.detailsContainer > .crisisSimulationIcon > .crisisDetailLogo {
  margin : auto;
  width: auto;
  height: 70px;
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}

.calendarContainer {
  background: var(--neutral-color);
  border: solid 2px var(--border-color);
  left: 5%;
  padding-bottom: 1.5vh !important;
  position: absolute;
  width: 95%;
  z-index: 100;
}

.daysNames {
  padding: 5px !important;
  text-transform: capitalize;
  padding: 0.5em 0;
  font-size: var(--normal);
  font-weight: bold;
  line-height: 1.65;
  text-align: center;
}

.daysNumbers {
  padding: 5px !important;
  text-align: center !important;
}

.disabled {
  color: #fff;
  pointer-events: none;
}

.selected {
  color: #0379ba !important;
  font-weight: bold;
}

.disabled > .number {
  cursor: pointer;
  font-size: var(--large);
  line-height: 1.65;
  color: var(--border-color);
}

.number {
  cursor: pointer;
  font-size: var(--large);
  line-height: 1.65;
  color: var(--button-color);
}

.number:hover {
  color: var(--button-color) !important;
}

.headerCalendar {
  color: var(--main-color);
  font-size: var(--xxlarge);
  font-weight: bold;
  line-height: 1.3;
  padding: 1rem 5px !important;
}

.calendarHeaderButton {
  align-items: center !important;
  color: var(--main-color) !important;
  cursor: pointer !important;
  display: flex !important;
  font-size: var(--small) !important;
  font-weight: bold;
  line-height: 1.3;
  justify-content: center !important;
  padding: 1rem 5px !important;
  text-align: center;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .calendarContainer {
    left: 6%;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .calendarContainer {
    left: 6%;
  }
}

.ui.table thead tr th.team-members-table__header-cell {
  background-color: #53a6d8 !important;
  padding: 0;
  height: 32px !important;
}

.leftTitle {
  display: block;
  margin: 2em 0 0 3em;
  text-align: left;
}

.leftTitle > .leftTitleContent {
  display: flex;
  justify-content: space-between;
  max-width: 23%;
  align-items: center;
}

.leftTitle > .leftTitleContent > p {
  font-size: var(--xxlarge);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
}

.blackLine {
  background-color: #010101;
  height: 2px;
  margin-bottom: 21px;
  width: 52px;
  display: inline-table;
}

.crisis-_teams-stay-the-same__toggle {
    display: flex;
    align-items: center;   
}
  
.crisis-_teams-stay-the-same__toggle label{
    margin-right: 1.5rem;
}

.crisis-run-again--form-row  .crisisSimulationIcon {
    height: 100px;
    width: 100px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 22px;
    display: flex;
    background-color: #18b1b9;
    justify-content: center;
}
  
.crisis-run-again--form-row  .crisisSimulationIcon > .crisisDetailLogo {
    margin : auto;
    width: auto;
    height: 70px;
}

.create-next-crisis-simulation__game-mode--help{
    margin-top: 20px;
    max-width: 100%;
    padding: 1.75rem;
    background-color: var(--neutral-color);
    border-radius: 25px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}
.crisis-bad-link__body-container {
  padding: 1.5rem;
  background: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.crisis-bad-link__error-text {
  color: red;
}

.crisis-bad-link__todo-list {
  margin-bottom: 0;
}

.dashBoardContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
}

.noDashboardContainer {
  padding: 0 20%;
}

.rightSideContainer {
  height: 100%;
  width: 84%;
  padding: 0 10% 0 2%;
  overflow-y: auto;
}

.leftSideContainer {
  height: 100%;
  width: 18%;
  padding-top: 10%;
}

.simulationRefresh {
  display: flex;
  align-items: center;
  padding: 20px 0% 20px 0%;
}

.simulationRefresh > p {
  font-size: var(--xlarge);
  margin: 0 20px 0 0;
}

.teamSelector {
  display: none;
  align-items: center;
}

.teamItemsContent {
  display: flex;
  flex-flow: wrap;
  max-height: 80px;
  overflow: auto;
  margin-left: 10px;
  background-color: var(--neutral-color);
}

.teamItem {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0 !important;
  color: var(--button-color);
  font-size: var(--normal);
  margin: 5px 10px;
  height: auto;
  min-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamItem > label {
  font-size: var(--normal);
  margin: 0 10px;
  width: auto;
  white-space: normal;
}

.teamItem > button {
  background: transparent;
  border: none;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openChartModalButton {
  height: 20px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.openChartModalButton:hover {
  background: var(--border-color);
}

.openChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.closeChartModalButton {
  position: absolute;
  height: 40px;
  right: 6px;
  top: 8px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.closeChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.ui.grid > .column > div > .column > .sectionGraphHeader > h3 {
  background-color: var(--neutral-color);
  max-width: 200px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.chartContainer {
  padding-bottom: 25px;
  width: 190px;
  height: 180px;
}

.noShowGraphGrid {
  position: absolute !important;
  top: -9999px;
  left: -9999px;
}

.showGraphGrid {
  position: inherit;
  margin-bottom: 5vh;
}

.chartContainer > .chartjs-render-monitor {
  background-color: var(--neutral-color);
  border: 2px solid var(--button-color);
}

.teamsAsideContainer {
  height: 100%;
  padding: 10px 20px;
}

.teamsAsideListContainer {
  height: 90%;
  overflow-y: auto;
  padding-bottom: 100px;
}

.dashBoardTeamCheckbox {
  margin: 0 10px;
}

.dashBoardTeamsList {
  padding: 5%;
  background-color: #fff;
  border: 2px solid var(--button-color);
}

.ui.form.dashBoardTeamsList > .field {
  margin-bottom: 0.5em;
}

.dashBoardTeamItem {
  display: flex;
  align-items: center;
}

.dashBoardGrid {
  height: 100%;
}

.dashBoardListColumn {
  max-height: 100%;
  overflow-y: auto;
}

.dashBoardChartsColumn {
  padding-right: 14% !important;
  max-height: 100%;
  overflow-y: auto;
}

.teamListHeader {
  padding: 10px 0 0 0;
}

.teamListHeader > h3 {
  margin: 15px 0 10px 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.selectAllButton {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.specialSelection {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.selectAllButton:hover,
.specialSelection:hover {
  color: var(--button-color);
  background: var(--border-color);
  border: 1px solid var(--border-color);
}

.selectAllButton:active,
.specialSelection:active,
.specialSelection:focus {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.disabled {
  color: var(--text-color-light);
}

.teamItem {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.teamItemLabel {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.dashBoardTeamItem > label {
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  word-break: break-word;
}

.specialGraphColumn > .chartContent {
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.specialGraphColumn > .chartContent > h5 {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 10px;
  padding: 10px 25px;
}

.scrollToButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  z-index: 1;
}

.dashboardLoadingContainer {
  height: 150px;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallGraph {
  height: 160px;
  width: calc(100% - 5px);
  position: relative;
  margin: 30px 20px 50px 0;
}

#bigGraph {
  height: 450px;
  width: calc(100% - 5px);
  margin: 20px;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 4%;
  left: 20%;
  width: auto;
  border-radius: 10px;
}

.tooltip > span {
  color: var(--neutral-color);
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip:first-child {
  padding-top: 5px;
}

.tooltip > span:last-child {
  padding-bottom: 10px;
}

.sectionSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0% 20px 0%;
  width: 100%;
}

.showMore {
  position: absolute;
  bottom: 40px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
}

.addMoreCharts {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--button-color);
  color: var(--neutral-color);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  font-size: 25px;
}

.titleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.buttonGraphGroup {
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectGraphButton {
  background: var(--neutral-color);
  border: none;
  color: var(--button-color);
  font-size: var(--normal);
  padding: 5px 8px;
  height: 35px;
  z-index: 2;
}

.selectGraphButton.selectedGraph {
  background: rgba(3, 121, 186, 0.1);
  font-weight: bold;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.buttonLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px 0 1px 1px;
  border-color: var(--button-color);
  border-style: solid;
}

.buttonRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px 1px 1px 0;
  border-color: var(--button-color);
  border-style: solid;
}

.selectGraphButton:hover {
  color: var(--button-color);
  background: var(--border-color);
}

.selectGraphButton:focus {
  outline: none;
}

.tryAgainContent {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .titleSection {
    display: grid;
    margin-bottom: 20px;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 440px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }

  .dashBoardGrid {
    height: 100%;
  }

  .dashBoardContainer {
    padding-left: 0;
  }

  .noDashboardContainer {
    padding: 5%;
  }

  .dashBoardChartsColumn {
    padding: 0;
  }

  .simulationRefresh {
    padding: 14px 0 14px 0;
  }

  .smallGraph {
    margin: 30px 5px 30px 5px;
  }

  .openChartModalButton {
    right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .teamSelector {
    display: flex;
  }
}

.homeMiddle {
  min-height: auto;
  margin: 5vh 0 0 0;
}

.headerCellIcon {
  size: "lg";
  color: "#FFF";
}

.detailButtons {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.headerCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .tableContainer {
    overflow-x: scroll;
  }
}

.medica-bad-link__body-container {
  padding: 1.5rem;
  background: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.medica-bad-link__error-text {
  color: red;
}

.medica-bad-link__todo-list {
  margin-bottom: 0;
}

.questionnaireForm {
  text-align: center;
  max-width: 400px;
  margin: 5% auto 0 auto;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .questionnaireForm {
    width: 100%;
  }
}

.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.detailsContainer > .simulationIcon > .medicaLogo {
  height: 100px;
  width: 100px;
  background-color: var(--iconBkg-color);
  padding: 10px;
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .detailsContainer > .simulationIcon > .medicaLogo {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }

  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}

.loadingContainer {
  display: flex !important;
  height: 100vh !important;
  width: 100% !important;
}

.loadingInformation {
  height: 40 !important;
  margin: auto !important;
  min-width: 20 !important;
}

.loader {
  border: 4px solid #f3f3f3 !important;
  border-radius: 50% !important;
  border-top: 4px solid #0379ba !important;
  margin: 20px auto !important;
  height: 40px !important;
  width: 40px !important;
  -webkit-animation: spin 1s linear infinite !important;
          animation: spin 1s linear infinite !important;
}

.teamLeadConfirmation {
  font-size: var(--large);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cardFormContainer {
  padding: 0 150px;
}

.StripeElement {
  padding: 5px;
  width: 100%;
  box-shadow: none;
  background-color: var(--neutral-color);
  margin: auto;
  box-sizing: unset;
}

.StripeElement.StripeElement--empty {
  background-color: var(--neutral-color);
}

.StripeElement--focus {
  box-shadow: 0;
}

.StripeElement--invalid {
  border-color: var(--danger-color);
}

.centeredLabel {
  height: 18px;
  font-size: var(--large);
  line-height: 0.75;
  letter-spacing: 1.8px;
  text-align: center;
  margin-top: var(--normal);
}

.discountLabel {
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
  height: 18px;
  font-size: var(--normal);
  line-height: 0.75;
  letter-spacing: 1.8px;
  text-align: center;
  margin-top: var(--xlarge);
}

.discountLabel:hover {
  color: #002A61;
}

.column.breakdown {
  margin: 2vw;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .cardFormContainer {
    padding: 0;
  }

  .StripeElement {
    width: 100%;
    padding: 2px;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .cardFormContainer {
    padding: 0;
  }

  .StripeElement {
    width: 100%;
    padding: 2px;
  }
}

.ui.grid.paymentContainer{
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #888888;
    padding-right: 4px;
}
.profileFrame {
  display: flex;
  height: 50vh;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

.profileIconContainer {
  width: 40%;
  height: 100%;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  height: 65%;
}

.profileDetailsContainer {
  width: 58%;
  height: 60%;
  display: flex;
  align-items: center;
}

.profileDetailsContainer > .detailsData {
  min-width: 85%;
  min-height: 40vh;
  padding: 5vh;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.profileDetailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xxlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.profileDetailsContainer > .detailsData > .list > .item {
  font-size: var(--xlarge);
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.profileDetailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.profileDetailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 742px) and (orientation: portrait) {
  .profileDetailsContainer {
    margin-top: 25%;
  }

  .profileIconContainer {
    display: none;
  }

  .profileDetailsContainer {
    width: 100%;
  }

  .profileDetailsContainer > .detailsData {
    width: 100%;
  }

  .profileDetailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .profileDetailsContainer > .detailsData > .list > .item {
    font-size: var(--normal);
  }
}

@media only screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait) {
  .profileImage {
    height: 45%;
  }

  .profileDetailsContainer {
    width: 50%;
  }

  .profileIconContainer {
    width: 45%;
    height: 70%;
  }

  .profileImage {
    height: 50%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: portrait) {
  .profileImage {
    height: 50%;
  }

  .profileDetailsContainer {
    width: 60%;
  }

  .profileIconContainer {
    width: 50%;
    height: 70%;
  }

  .profileImage {
    height: 50%;
  }
}

.abogadosBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.abogadosBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}

.abogadosBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.crisisBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.crisisBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}

.create-crisis-simulation__game-mode-help-list {
  text-align: justify;
}

.create-crisis-simulation__game-mode-help-list li:not(:last-child) { 
  margin-bottom: 10px; 
}

.crisisBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.calendarPosition {
  position: fixed;
  left: 45%;
  top: 25%;
  width: 35vw;
  z-index: 1;
}

#whiteAndBlueButton {
  background-color: transparent;
  border-radius: 0;
  color: var(--main-color);
  font-size: var(--normal);
  margin: 0;
  padding: 10px 5px;
  display: flex;
  align-items: center;
}

.informativeParagraphContainer {
  display: block;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.informativeParagraph {
  text-align: justify;
}

#whiteAndBlueButton:hover {
  background-color: #eee;
  border-radius: 0;
  color: var(--main-color);
}

#imageButton {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .calendarPosition {
    left: 0;
    width: 100%;
  }

  .informativeParagraphContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .calendarPosition {
    left: 25%;
    top: 25%;
    width: 50%;
  }
}

.scenarioInformation {
  width: 100%;
  margin-top: 2vh;
}

.scenarioInformationContent {
  padding: 2vh 5vh;
  background-color: var(--neutral-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.scenarioInformationContent:first-child {
  margin-bottom: 10px;
}

.scenarioComparisonContent {
  max-width: 100%;
}

.scenarioTableContainer {
  margin: 50px 0;
  width: 100%;
}

.scenarioGraphContainer {
  border-radius: 25px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.rv-discrete-color-legend-item.horizontal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.customTable {
  margin-bottom: 5vh;
}

.customTable.smallTable {
  margin-top: 5vh;
}

.customTable > .body {
  display: flex;
  width: 100%;
  font-size: var(--normal);
  border-top: 1px solid var(--neutral-color);
}

.customTable > .body:first-child {
  margin: 0;
  border: none;
}

.customTable > .body:last-child {
  border-top: 1px solid var(--neutral-color);
  border-bottom: 2px solid var(--main-color);
}

.customTable > .body:last-child > .sectionConfigurations > .row:last-child {
  border-bottom: none;
}

.customTable > .body > .sectionName {
  width: 5%;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customTable.smallTable > .body > .sectionName {
  width: 20%;
}

.customTable > .body > .sectionName > p {
  transform: rotate(-90deg);
  white-space: nowrap;
  color: var(--neutral-color);
  font-weight: bold;
}

.customTable.smallTable > .body > .sectionName > p {
  transform: rotate(0);
  text-align: center;
  word-break: break-word;
}

.customTable > .body > .sectionConfigurations {
  width: 95%;
}

.customTable.smallTable > .body > .sectionConfigurations {
  width: 80%;
}

.customTable > .body > .sectionConfigurations > .row {
  display: flex;
  min-height: 40px;
  border-bottom: 1px solid var(--main-color);
  border-right: 1px solid var(--main-color);
}

.headerCustomTable {
  background-color: var(--main-color);
  white-space: nowrap;
  color: var(--neutral-color);
  font-weight: bold;
}

.customTable > .body > .sectionConfigurations > .row:first-child {
  border-top: none;
}

.customTable > .body > .sectionConfigurations > .row > .rowItem {
  width: 25%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowItem > p {
  text-align: left;
  width: 100%;
  white-space: break-spaces;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue {
  width: 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  text-align: center;
  word-break: break-all;
  border-left: 1px solid var(--main-color);
}

.customTable > .body > .sectionConfigurations > .row > .rowValue > div {
  text-align: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue > div {
  padding: 5px 0;
}

.customTable > .body > .sectionConfigurations > .row:nth-child(odd) {
  background-color: rgba(215, 215, 215, 0.5);
}

.customTable > .body > .sectionConfigurations > .row:nth-child(even) {
  background-color: var(--neutral-color);
}

.unitsIfPriceRatio {
  font-size: 12px;
}

.medica-advanced-options__toggle{
  display: flex;
  align-items: center;
}

.medica-advanced-options__toggle label{
  margin-right: 1.5rem;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .scenarioInformationContent {
    padding: 3vh 4vw;
  }

  .customTable {
    margin-bottom: 0;
  }

  .customTable.smallTable {
    margin-top: 3vh;
  }

  .scenarioGraphContainer {
    min-height: 52vh;
    padding: 20px;
  }

  .customTable > .body {
    font-size: var(--xsmall);
  }

  .customTable > .body > .sectionConfigurations > .row > .rowItem > p {
    text-align: left;
  }

  .customTable > .body > .sectionConfigurations > .row > .rowValue.blockValue > div {
    padding: 0;
  }

  .scenarioTableContainer {
    overflow-x: scroll;
  }

  .customTable.smallTable > .body > .sectionName > p {
    white-space: normal;
  }

  .unitsIfPriceRatio {
    font-size: 7px;
  }
}

.tableContainer > .profesorScenarioTable > tbody > tr > td.buttonCell > button.actionButton {
  background-color: transparent;
  padding: 8px 20px;
}

.tableContainer > .profesorScenarioTable > tbody > tr > td.buttonCell > button.actionButton:hover {
  color: var(--button-color);
  background-color: var(--iconBkg-color);
}

.tableContainer
  > .profesorScenarioTable
  > tbody
  > tr
  > td.buttonCell
  > button.actionButton:hover
  > .tableIcon.trashIcon
  > path {
  color: rgba(255, 0, 0, 0.7);
}

.scenarioManagerTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scenarioManagerInfoMessage {
  margin-top: 8vh;
}

.scenarioManagerTableTopper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.scenarioManagerIntroText {
  margin-bottom: 0px;
  width: 75%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: justify;
}

.scenarioManagerCreateScenarioButton {
  justify-self: end;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .scenarioManagerTitleContainer {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .scenarioManagerIntroText {
    width: 100%;
  }

  .scenarioManagerTitleContainer > .defaultButton {
    position: inherit;
  }
}

.storeDownloadIcon {
  object-fit: scale-down;
  height: 4.3vh;
  width: auto;
  cursor: pointer;
}

.iconDownload {
  margin-top: 5vh;
}

.downloadTitle {
  margin-bottom: 2vh;
}

.downloadAppIconBackground {
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  padding: 2vh;
  background: #f9f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadLinkEmbeddedIcon.crisisDownloadAppLogo, 
.downloadAppIconBackground.crisisDownloadAppLogo {
  background: #18b1b9;
}

.downloadLinkEmbeddedIcon.supplyChainDownloadAppLogo,
.downloadAppIconBackground.supplyChainDownloadAppLogo {
  background: #006F5D;
}

.downloadLinkEmbeddedIcon.simunationDownloadAppLogo,
.downloadAppIconBackground.simunationDownloadAppLogo {
  background: rgb(126, 33, 126);
}

.downloadLinkEmbeddedIcon {
  height: 150px;
  width: 150px;
  border-radius: 30px;
  display: flex;
  margin:auto;
  align-items: center;
  justify-content: center;
}

.downloadAppGrid {
  height: 90vh;
}

.infoFrame {
  padding: 20px 25px;
  background: var(--neutral-color);
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.downloadAppInstructionsText {
  font-size: var(--xlarge);
  text-align: justify;
}

.windowsLink {
  margin-top: 6px;
  text-align: center;
  font-size: var(--medium);
  color: var(--button-color);
}

.windowsLink a {
  color: var(--main-color);
}

@media screen and (max-width: 767px) {
  .downloadColumn {
    margin-top: 0;
    min-height: 65vh;
  }

  .logoContainer > .logoBackground {
    height: 77px;
    width: 77px;
    border-radius: 20px;
  }

  .storeDownloadIcon {
    height: auto;
    cursor: pointer;
    width: 210px;
  }
}

.payFormSimunationLabel {
  font-size: var(--large);
  letter-spacing: 1.5px;
}

.detailsContainer > .simunationIcon {
  height: 100px;
  width: 100px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 22px;
  display: flex;
  background-color: #7e217e;
  justify-content: center;
}

.detailsContainer > .simunationIcon > .simunationLogo {
  margin : auto;
  width: 70px;
}
.teamContainer {
  text-align: center;
  width: 50%;
  margin: 5% auto 0 auto;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}

.StudentJoinTeamComponent_loadingContainer__qkQe3 {
  display: flex !important;
  height: 30vh !important;
  width: 100% !important;
}

.StudentJoinTeamComponent_loadingInformation__3JziK {
  height: 40px !important;
  margin: auto !important;
  min-width: 20px !important;
}

.StudentJoinTeamComponent_loader__2p0DO {
  border: 4px solid #f3f3f3 !important;
  border-radius: 50% !important;
  border-top: 4px solid #0379ba !important;
  margin: 20px auto !important;
  height: 40px !important;
  width: 40px !important;
  -webkit-animation: StudentJoinTeamComponent_spin__1Oi3f 1s linear infinite !important;
          animation: StudentJoinTeamComponent_spin__1Oi3f 1s linear infinite !important;
}

.StudentJoinTeamComponent_sub__3j26P.StudentJoinTeamComponent_header__1zP0t {
  font-size: var(--large) !important;
}

@-webkit-keyframes StudentJoinTeamComponent_spin__1Oi3f {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes StudentJoinTeamComponent_spin__1Oi3f {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.faqAccordion.ui.accordion {
  margin: 20px 0;
  padding: 15px;
  background: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.faqAccordion.ui.accordion > p {
  margin: 20px 0;
  text-align: center;
  font-size: var(--normal);
}

.faqAccordion.ui.accordion > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--large);
}

.faqAccordion.ui.accordion > .title.activeItem {
  color: var(--main-color);
}

.faqAccordion.ui.accordion > .content > p {
  font-size: var(--large);
}

.messageComponentContainer {
  margin: 40px 0;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .messageComponentContainer {
    margin: 0;
  }
}

.supplychainBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}
  
.supplychainBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}
  
.create-supplychain-simulation__game-mode-help-list {
  text-align: justify;
}
  
.create-supplychain-simulation__game-mode-help-list li:not(:last-child) { 
  margin-bottom: 10px; 
}
  
.supplychainBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}
.sectionVideoHeader {
  background-color: var(--neutral-color);
  max-width: 200px;
  margin: 0 10px;
  padding: 10px 25px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.videoFrameContainer {
  padding: 20px 25px;
  background: var(--neutral-color);
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.videoDescriptionContainer {
  padding: 10px 0;
}

.descriptionText {
  font-size: var(--small);
}

.videoOverlay {
  position: absolute;
  width: 80%;
  height: 55%;
  top: 10%;
  left: 10%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.videoOverlay:focus,
.videoOverlay:active {
  outline: none;
}

.youtubeIcon {
  opacity: 0.8;
}

.dashBoardContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
}

.noDashboardContainer {
  padding: 0 20%;
}

.rightSideContainer {
  height: 100%;
  width: 84%;
  padding: 0 10% 0 2%;
  overflow-y: auto;
}

.leftSideContainer {
  height: 100%;
  width: 18%;
  padding-top: 10%;
}

.simulationRefresh {
  display: flex;
  align-items: center;
  padding: 20px 0% 20px 0%;
}

.simulationRefresh > p {
  font-size: var(--xlarge);
  margin: 0 20px 0 0;
}

.teamSelector {
  display: none;
  align-items: center;
}

.teamItemsContent {
  display: flex;
  flex-flow: wrap;
  max-height: 80px;
  overflow: auto;
  margin-left: 10px;
  background-color: var(--neutral-color);
}

.teamItem {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0 !important;
  color: var(--button-color);
  font-size: var(--normal);
  margin: 5px 10px;
  height: auto;
  min-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamItem > label {
  font-size: var(--normal);
  margin: 0 10px;
  width: auto;
  white-space: normal;
}

.teamItem > button {
  background: transparent;
  border: none;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openChartModalButton {
  height: 20px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.openChartModalButton:hover {
  background: var(--border-color);
}

.openChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.closeChartModalButton {
  position: absolute;
  height: 40px;
  right: 6px;
  top: 8px;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.closeChartModalButton:active {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.sixteen.wide.column.sectionGraphHeaderCrisis {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.header.sectionGraphHeaderCrisis_title {
  background-color: var(--neutral-color);
  max-width: 400px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.header.sectionGraphHeaderCrisis_title > .icon{
  color: #0379ba;
  cursor: pointer;
  font-size: 16px;
  transition: all .2s linear;
}
.ui.header.sectionGraphHeaderCrisis_title > .icon__down{
  transform: rotate(180deg);
}

.chartContainer {
  padding-bottom: 25px;
  width: 190px;
  height: 180px;
}

.noShowGraphGrid {
  position: absolute !important;
  top: -9999px;
  left: -9999px;
}

.showGraphGrid {
  position: inherit;
  margin-bottom: 5vh;
}

.chartContainer > .chartjs-render-monitor {
  background-color: var(--neutral-color);
  border: 2px solid var(--button-color);
}

.teamsAsideContainer {
  height: 100%;
  padding: 10px 20px;
}

.teamsAsideListContainer {
  height: 90%;
  overflow-y: auto;
  padding-bottom: 100px;
}

.dashBoardTeamCheckbox {
  margin: 0 10px;
}

.dashBoardTeamsList {
  padding: 5%;
  background-color: #fff;
  border: 2px solid var(--button-color);
}

.ui.form.dashBoardTeamsList > .field {
  margin-bottom: 0.5em;
}

.dashBoardTeamItem {
  display: flex;
  align-items: center;
}

.dashBoardGrid {
  height: 100%;
}

.dashBoardListColumn {
  max-height: 100%;
  overflow-y: auto;
}

.dashBoardChartsColumn {
  padding-right: 14% !important;
  max-height: 100%;
  overflow-y: auto;
}

.teamListHeader {
  padding: 10px 0 0 0;
}

.teamListHeader > h3 {
  margin: 15px 0 10px 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.selectAllButton {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.specialSelection {
  background: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--normal);
  padding: 5px 8px;
}

.selectAllButton:hover,
.specialSelection:hover {
  color: var(--button-color);
  background: var(--border-color);
  border: 1px solid var(--border-color);
}

.selectAllButton:active,
.specialSelection:active,
.specialSelection:focus {
  -webkit-animation: controlledFade 0.4s;
          animation: controlledFade 0.4s;
  outline: none;
}

.disabled {
  color: var(--text-color-light);
}

.teamItem {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.teamItemLabel {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.dashBoardTeamItem > label {
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  word-break: break-word;
}

.specialGraphColumn > .chartContent {
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.specialGraphColumn > .chartContent > h5 {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 10px;
  padding: 10px 25px;
}

.scrollToButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  z-index: 1;
}

.dashboardLoadingContainer {
  height: 150px;
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallGraph {
  height: 160px;
  width: calc(100% - 5px);
  position: relative;
  margin: 30px 20px 50px 0;
}

#bigGraph {
  height: 450px;
  width: calc(100% - 5px);
  margin: 20px;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 4%;
  left: 20%;
  width: auto;
  border-radius: 10px;
}

.tooltip > span {
  color: var(--neutral-color);
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip:first-child {
  padding-top: 5px;
}

.tooltip > span:last-child {
  padding-bottom: 10px;
}

.sectionSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0% 20px 0%;
  width: 100%;
}

.showMore {
  position: absolute;
  bottom: 40px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
}

.addMoreCharts {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--button-color);
  color: var(--neutral-color);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  border: none;
  font-size: 25px;
}

.titleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.buttonGraphGroup {
  width: 0%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}

.selectGraphButton {
  background: var(--neutral-color);
  border: none;
  color: var(--button-color);
  font-size: var(--normal);
  padding: 5px 8px;
  height: 35px;
}

.selectGraphButton.selectedGraph {
  background: rgba(3, 121, 186, 0.1);
  font-weight: bold;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.buttonLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-width: 1px 0 1px 1px;
  border-color: var(--button-color);
  border-style: solid;
}

.buttonRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px 1px 1px 0;
  border-color: var(--button-color);
  border-style: solid;
}

.selectGraphButton:hover {
  color: var(--button-color);
  background: var(--border-color);
}

.selectGraphButton:focus {
  outline: none;
}

.tryAgainContent {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes controlledFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .titleSection {
    display: grid;
    margin-bottom: 20px;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }
}

@media only screen and (max-width: 440px) and (orientation: portrait) {
  .leftSideContainer {
    display: none;
  }

  .rightSideContainer {
    height: 100%;
    width: 100%;
    padding: 5%;
  }

  .dashBoardGrid {
    height: 100%;
  }

  .dashBoardContainer {
    padding-left: 0;
  }

  .noDashboardContainer {
    padding: 5%;
  }

  .dashBoardChartsColumn {
    padding: 0;
  }

  .simulationRefresh {
    padding: 14px 0 14px 0;
  }

  .smallGraph {
    margin: 30px 5px 30px 5px;
  }

  .openChartModalButton {
    right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .teamSelector {
    display: flex;
  }
}

.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.detailsContainer > .supplyChainSimulationIcon {
  height: 100px;
  width: 100px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 22px;
  display: flex;
  background-color: #006F5D;
  justify-content: center;
}


.ui.image.supplyChainDetailLogo {
  margin : auto;
  width: auto;
  height: 50px;
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .detailsContainer > .simulationIcon > .medicaLogo {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }

  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}

.ui.table thead tr th.team-members-table__header-cell {
  background-color: #53a6d8 !important;
  padding: 0;
  height: 32px !important;
}

.supply-chain-_teams-stay-the-same__toggle {
    display: flex;
    align-items: center;   
}
  
.supply-chain-_teams-stay-the-same__toggle label{
    margin-right: 1.5rem;
}

.supply-chain-run-again--form-row  .supplyChainSimulationIcon {
    height: 100px;
    width: 100px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 22px;
    display: flex;
    background-color: #006F5D;
    justify-content: center;
}
  
.supply-chain-run-again--form-row  .supplyChainSimulationIcon > .supplyChainDetailLogo {
    margin : auto;
    width: auto;
    height: 70px;
}

.create-next-supply-chain-simulation__game-mode--help{
    margin-top: 20px;
    max-width: 100%;
    padding: 1.75rem;
    background-color: var(--neutral-color);
    border-radius: 25px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}
.creambowBottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.creambowBottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}

.creambowBottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.rightColumn {
  align-items: center !important;
  background-color: var(--background-color);
  border: solid 1px var(--background-color);
  display: flex !important;
  justify-content: center !important;
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
}

.rightColumn > .buttonsContainer {
  width: 100%;
}

.rightColumn > .buttonsContainer > .actionButton {
  background-color: transparent;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--text-color-light);
  display: block;
  font-weight: bold;
  line-height: 1.33;
  font-size: var(--large) !important;
  letter-spacing: 1.4px;
  padding: 18px 25px 18px 5px;
  text-align: right;
  width: 100%;
  border: 5px solid;
  position: relative;
}

.rightColumn > .buttonsContainer > .actionButton:focus {
  border: none !important;
}

.rightColumn > .buttonsContainer > .actionButton:after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 25px;
  border: 1px solid var(--text-color-light);
}

.rightColumn > .buttonsContainer > .actionButton:hover {
  background-color: var(--neutral-color) !important;
  border: none !important;
  color: var(--text-color-light) !important;
}

.leftColumn {
  min-height: 102vh !important;
  padding: 0 0 5% 0 !important;
  width: 100% !important;
}

.headerSimulationName {
  padding-right: 5%;
  text-align: right;
}

.headerSimulationName > h1 {
  color: var(--main-color);
  font-size: var(--bigger);
  word-wrap: break-word;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.headerSimulationName > .specialLine {
  height: 2px;
  width: 50px;
  background-color: var(--text-color-light);
  display: inline-block;
}

.ui.image.creambowDetailLogo {
  height: 100px;
  width: 100px;
  background: #FAF9F9;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsContainer > .detailsData {
  margin-top: 20px;
  max-width: 100%;
  padding: 10px;
  background-color: var(--neutral-color);
  border-left: 10px solid var(--main-color);
  border-radius: 25px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.detailsDataLoading {
  background-color: transparent;
  width: auto;
}

.detailsDataCharged {
  background-color: var(--background-color);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.detailsContainer > .detailsData > p {
  margin: 0;
  font-size: var(--xlarge);
  font-weight: bold;
  line-height: 1.65;
  letter-spacing: 0.4px;
}

.detailsContainer > .detailsData > .list > .item {
  font-size: var(--large);
  word-wrap: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  margin: 5px;
  width: 100%;
  padding-left: 10px;
}

.detailsContainer > .detailsData > .list > .item:first-child {
  padding-top: 20px;
}

.detailsContainer > .detailsData > .list > .item:last-child {
  padding-bottom: 20px;
}

.documentLanguageSelector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.animated-background {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: var(--large);
  width: 50%;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media only screen and (max-width: 767px) {
  .detailsContainer > .simulationIcon > .medicaLogo {
    height: 60px;
    width: 60px;
    border-radius: 12px;
  }

  .animated-background {
    width: 70% !important;
  }

  .detailsContainer > .detailsData {
    width: auto;
    padding: 10px 0;
    border-left: none;
  }

  .detailsContainer > .detailsData > p {
    font-size: var(--large);
    padding-left: 10px;
  }

  .detailsContainer > .detailsData > .list > .item {
    font-size: var(--normal) !important;
  }

  .headerSimulationName > h1 {
    font-size: var(--xxxlarge);
    word-wrap: break-word;
  }
}

.ui.table thead tr th.team-members-table__header-cell {
  background-color: #53a6d8 !important;
  padding: 0;
  height: 32px !important;
}

.ui.vertical.menu.decision-history-tab__menu{
    border: 2px solid gray !important
}
.ui.list.selection > .item.product-item__faq-products {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF50
}

.ui.list.selection.list > .item.product-item__faq-products:hover {
    background-color: hsla(0, 0%, 100%, 0.8);
    box-shadow: 3px 3px #BBBBBBCC;
}

.ui.list.selection > .item.product-item__faq-products > .content {
    display: inline-block;
    margin-left: 1rem;
    
}

.product-avatar__faq_products {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.item.product-item__faq-products > .product-avatar__faq_products  img.image {
    width: auto;
    height: auto;
    max-width: 50px;
    max-height: 50px;  
    display: inline-block !important;
}


.ui.list > .item.product-item__faq-products > i.icon {
    display: inline-block;
    margin: 0 5px
}
