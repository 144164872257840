.crisis-_teams-stay-the-same__toggle {
    display: flex;
    align-items: center;   
}
  
.crisis-_teams-stay-the-same__toggle label{
    margin-right: 1.5rem;
}

.crisis-run-again--form-row  .crisisSimulationIcon {
    height: 100px;
    width: 100px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 22px;
    display: flex;
    background-color: #18b1b9;
    justify-content: center;
}
  
.crisis-run-again--form-row  .crisisSimulationIcon > .crisisDetailLogo {
    margin : auto;
    width: auto;
    height: 70px;
}

.create-next-crisis-simulation__game-mode--help{
    margin-top: 20px;
    max-width: 100%;
    padding: 1.75rem;
    background-color: var(--neutral-color);
    border-radius: 25px;
    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}