.bottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.bottomArea > .goBackButton {
  background-color: var(--neutral-color);
  border: 1px solid;
  border-radius: 0;
  color: var(--main-color);
  font-size: var(--normal);
  min-width: 135px;
}
.bottomArea > .goBackButton:hover {
  background-color: #eee;
  border-radius: 0;
  color: var(--main-color);
}

.noButtonArea > .goBackButton {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color);
  border-radius: 0;
  color: var(--button-color);
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
}
.noButtonArea > .goBackButton:hover {
  background-color: var(--button-color);
  color: var(--neutral-color);
}

.bottomArea > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
}

.bottomArea > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.productColumn > .bottomArea > .ui.basic.button {
  min-width: 90% !important;
  position: absolute;
  bottom: 0;
}

.logoutButton > .ui.basic.button {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
  height: 44px;
}

.logoutButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}

.defaultButton > .ui.basic.button {
  background-color: var(--neutral-color) !important;
  border: 2.5px solid var(--button-color);
  border-radius: 0;
  color: var(--button-color) !important;
  font-size: var(--normal);
  text-transform: uppercase;
  min-width: 135px;
  margin: 0px;
}

.defaultButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}
