.inputContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  height: 46px;
  margin: 0;
  background-color: var(--neutral-color);
  width: 100%;
}

.labelContainer {
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: center;
}

.labelContainer.leftLabelAlignment {
  justify-content: left;
}

.specialLabel {
  font-size: var(--large);
  font-weight: 400;
  letter-spacing: 1.5px;
  padding: 8px 0;
}

.inputContainer > .ui.input.customInput {
  width: 100%;
  height: 100%;
}

.inputContainer > .ui.input.customInput > input {
  border: none;
  border-radius: 0;
  font-size: var(--xlarge);
  color: var(--button-color);
  height: 100%;
  width: 100%;
  padding: 10px;
  cursor: text;
}

.inputContainer.boldContent > .ui.input.customInput > input {
  font-weight: bold;
  font-size: var(--xxxlarge);
  color: var(--button-color);
  opacity: 1;
}

.inputContainer.errorIndicator {
  border: 2px solid rgba(159, 58, 56, 0.5);
}

.errorMessage {
  background-color: #9f3a38;
  color: #fff;
  font-size: var(--xxx-small);
  margin: 20px;
  padding: 5px;
  white-space: nowrap;
  font-weight: 500;
  position: sticky;
  z-index: 1;
}

.numberIndicators {
  float: right;
  color: #b0adad;
}

.numberIndicators.errorCounter {
  color: #912d2b;
  font-weight: bold;
}

.textAreaContainer {
  border: 2px solid var(--border-color);
}

.textAreaContainer.errorIndicator {
  border: 2px solid rgba(159, 58, 56, 0.5);
}

.textAreaContainer > textarea.customTextArea {
  border: none;
  border-radius: 0;
  font-size: var(--xxlarge);
  color: var(--button-color);
  height: 100%;
  width: 100%;
  padding: 10px;
  resize: none;
}

.dateTimeSelectorContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  height: 45px;
  justify-content: center;
}

.dateTimeSelectorContainer > .inputItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--neutral-color);
  height: 100%;
  width: 100%;
}

.dateTimeSelectorContainer > .inputItem.dateInfo {
  width: 65%;
}

.dateTimeSelectorContainer > .inputItem.dateInfo > span {
  color: var(--button-color);
  cursor: pointer;
  font-size: var(--normal);
  letter-spacing: 2.2px;
  padding: 8px 5px;
}

.dateTimeSelectorContainer > .inputItem.timeSelector {
  width: 35%;
  padding: 0 10px;
}

.dateTimeSelectorContainer > .inputItem.timeSelector > .selector.dropdown {
  font-size: var(--normal);
  width: 50%;
}

.dateTimeSelectorContainer > .inputItem > img {
  height: 1.4em;
}

.inputSliderContainer {
  padding: 10px 0;
}

.sliderLabelContainer {
  margin: 10px 0;
}

.sliderLabelContainer > .labelContainer {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: left;
}

.sliderLabelContainer > .labelContainer.heightGrow > .specialLabel {
  width: 65%;
}

.sliderLabelContainer > .sliderControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sliderLabelContainer > .sliderControls > .buttonControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sliderLabelContainer > .sliderControls > .buttonControl > button {
  border: none;
  outline: none;
  height: 25px;
  width: 35px;
  background-color: var(--background-color);
}

.sliderLabelContainer > .sliderControls > .buttonControl > button:hover {
  border: 1px solid var(--main-color);
  background: var(--iconBkg-color);
}

.sliderLabelContainer > .sliderControls > p {
  font-size: var(--xxxlarge);
  margin: 0;
}

.inputSliderContainer > input[type="range"].inputSlider {
  height: 5px;
  -webkit-appearance: none;
  margin: 5px 0;
  width: 100%;
}

.inputSliderContainer > input[type="range"].inputSlider:focus {
  outline: none;
}

.inputSliderContainer > input[type="range"].inputSlider::-webkit-slider-runnable-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  background: var(--iconBkg-color);
  border-radius: 0px;
  border: 0px solid var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-webkit-slider-runnable-track {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-moz-range-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  background: var(--iconBkg-color);
  border-radius: 0px;
  border: 0px solid var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-moz-range-thumb {
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
}

.inputSliderContainer > input[type="range"]:focus::-moz-range-track {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-fill-lower {
  background: var(--iconBkg-color);
  border: 0px solid var(--iconBkg-color);
  border-radius: 0px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-fill-upper {
  background: var(--iconBkg-color);
  border: 0px solid var(--iconBkg-color);
  border-radius: 0px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
}

.inputSliderContainer > input[type="range"].inputSlider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px var(--iconBkg-color);
  border: 1px solid var(--main-color);
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: var(--main-color);
  cursor: pointer;
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-ms-fill-lower {
  background: var(--border-color);
}

.inputSliderContainer > input[type="range"].inputSlider:focus::-ms-fill-upper {
  background: var(--border-color);
}

.checkInputContainer {
  align-items: center;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  height: 46px;
  margin: 0;
  background-color: var(--neutral-color);
  width: 100%;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label {
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label::before {
  border: 2px solid var(--border-color);
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput > label::after {
  color: var(--main-color);
  height: 25px;
  width: 25px;
  font-size: 25px;
  padding: 3px 0;
}

.checkInputContainer > .ui.checkbox.customCheckInput input:checked:focus ~ label:before {
  border: 2px solid var(--main-color);
  height: 25px;
  width: 25px;
}

.checkInputContainer > .ui.checkbox.customCheckInput input:checked:focus ~ label:after {
  color: var(--main-color);
}

.toggleSelection {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  margin: auto;
}

.toggleSelection > label {
  margin-right: 10px;
}

.inputToggleContainer {
  margin: 10px 0;
  height: 46px;
}

.inputToggleContainer > .labelContainer {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-start;
}

.inputToggleContainer > .labelContainer > .toggle {
  margin-left: 20px;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .sliderLabelContainer > .labelContainer.heightGrow {
    width: 100%;
    height: 50px;
    display: inline-block;
  }

  .sliderLabelContainer > .labelContainer.heightGrow > .toggleSelection {
    width: 100%;
    position: initial;
    height: 100%;
    justify-content: center;
  }

  .sliderLabelContainer > .labelContainer > .specialLabel {
    width: 100%;
  }

  .sliderLabelContainer > .sliderControls > .buttonControl > p {
    padding-top: 10px;
    font-size: var(--small);
  }

  .sliderLabelContainer > .sliderControls > p {
    font-size: var(--normal);
  }
}
