.dropDownContainer {
  border: 2px solid #d7d7d7;
  height: 46px;
  width: 100%;
  background-color: var(--neutral-color);
}

.dropDownContainer > .ui.dropdown.customDropdown {
  align-items: center;
  border: none;
  display: flex;
  font-size: var(--xlarge);
  justify-content: space-between;
  color: var(--button-color);
  padding: 10px;
  height: 100%;
  width: 100%;
}
