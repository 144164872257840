.titleContainer {
  display: block;
  margin: 10px 0;
  min-height: 40px;
  text-align: center;
  width: 100%;
}

.titleTextContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleContainer > .titleTextContent > .title,
.titleContainer > .subtitle {
  font-size: var(--xxxlarge);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
}

.titleContainer > .title {
  text-transform: none;
}

.titleContainer > .reducedSubTitle {
  font-weight: 400;
  font-size: var(--xlarge);
}

.blackLine {
  background-color: #010101;
  height: 2px;
  margin-bottom: 21px;
  width: 52px;
  display: inline-table;
}

@media only screen and (max-width: 742px) {
  .titleContainer {
    margin: 2vh 0;
  }

  .titleContainer > .title {
    font-size: var(--xxlarge);
  }

  .titleContainer > .reducedSubTitle {
    font-size: var(--large);
  }
}
