.crisis-bad-link__body-container {
  padding: 1.5rem;
  background: var(--neutral-color);
  border-radius: 20px;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
}

.crisis-bad-link__error-text {
  color: red;
}

.crisis-bad-link__todo-list {
  margin-bottom: 0;
}
