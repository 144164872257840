.leftTitle {
  display: block;
  margin: 2em 0 0 3em;
  text-align: left;
}

.leftTitle > .leftTitleContent {
  display: flex;
  justify-content: space-between;
  max-width: 23%;
  align-items: center;
}

.leftTitle > .leftTitleContent > p {
  font-size: var(--xxlarge);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
}

.blackLine {
  background-color: #010101;
  height: 2px;
  margin-bottom: 21px;
  width: 52px;
  display: inline-table;
}
