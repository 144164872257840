.customHeader {
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  margin: 10px 0;
}
.customHeader > .headerLeft {
  align-items: center;
  display: flex;
  width: 20%;
  height: 100%;
}
.customHeader > .headerRight {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 80%;
}
.headerRight > .ui.dropdown > .dropdown.icon {
  display: none !important;
}

.dropDownItem {
  background-color: var(--background-color);
}

.dropDownItem > a {
  color: var(--button-color);
}

.dropDownItem > a:active,
.dropDownItem > a:focus,
.dropDownItem > a:hover {
  background-color: var(--button-detail-color) !important;
  color: var(--neutral-color) !important;
}

.headerRight > .onlyMobile {
  display: none;
}

.headerRight > .headerButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5% 0;
}

.headerRight > .headerButtonsContainer > .navLinkContainer {
  margin: 0 0 0 5%;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a {
  font-size: var(--large);
  background-color: var(---button-detail-color);
  border: none;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  color: var(--button-color);
  text-align: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a > .ui.label.headerLabel {
  font-size: var(--xsmall);
  background-color: var(--main-color);
  color: var(--neutral-color);
  font-weight: bold;
  top: -2em;
  padding: 5px;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a:focus,
.headerRight > .headerButtonsContainer > .navLinkContainer > a:active {
  outline: none;
}

.headerRight > .headerButtonsContainer > .navLinkContainer > a:hover {
  color: var(--main-color);
}

.headerRight > .dropdownHeader > .menu > .item.dropDownItem a > .ui.label.headerLabel {
  font-size: var(--small);
  background-color: var(--main-color);
  color: var(--neutral-color);
  position: absolute;
  top: 0;
  padding: 2.5px;
  font-weight: bold;
}

.selectedPath {
  color: var(--main-color);
}

.headerRight > .studentActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerRight > .studentActions > .languageSelector {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .headerRight > .onlyMobile {
    display: block;
  }

  .headerRight > .hideButtonsContainer {
    display: none;
  }

  .headerRight > .languageSelector {
    width: 60%;
    display: flex;
    margin: 2vh 0;
    padding: 0;
  }

  .customHeader > .headerRight {
    flex-direction: column-reverse;
    padding: 10px;
    align-items: flex-end;
  }

  .headerRight > .studentActions {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .headerRight > .studentActions > .languageSelector {
    margin: 10px 0 0 0;
    padding: 0;
  }

  .headerRight > .studentActions > .languageSelector > .field {
    width: 98%;
  }
}
