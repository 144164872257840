.calendarPosition {
  position: fixed;
  left: 45%;
  top: 25%;
  width: 35vw;
  z-index: 1;
}

#whiteAndBlueButton {
  background-color: transparent;
  border-radius: 0;
  color: var(--main-color);
  font-size: var(--normal);
  margin: 0;
  padding: 10px 5px;
  display: flex;
  align-items: center;
}

.informativeParagraphContainer {
  display: block;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.informativeParagraph {
  text-align: justify;
}

#whiteAndBlueButton:hover {
  background-color: #eee;
  border-radius: 0;
  color: var(--main-color);
}

#imageButton {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .calendarPosition {
    left: 0;
    width: 100%;
  }

  .informativeParagraphContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .calendarPosition {
    left: 25%;
    top: 25%;
    width: 50%;
  }
}
