.ui.modal > .header:not(.ui) {
  font-size: var(--xxxlarge);
  font-weight: bold;
  line-height: 0.96;
  color: #000000;
}

.ui.modal > .actions {
  background: #fff;
  text-align: center !important;
}

.ui.modal > .actions > .button {
  background-color: transparent !important;
  border: 2.5px solid #272727 !important;
  border-radius: 0 !important;
  color: #272727 !important;
  font-size: var(--normal) !important;
}

.ui.modal > .actions > .button:hover {
  background-color: #272727 !important;
  color: #fff !important;
}

.ui.form .field > .selection.dropdown > .dropdown.icon {
  float: right;
  display: none;
}

.ui.selection.dropdown {
  border: none;
  font-size: var(--xlarge);
  color: var(--button-color);
  min-height: 0;
  min-width: 30%;
  padding: 10px 0;
  text-align: center;
}

.ui.selection.dropdown .menu > .item {
  padding: 10px 0 !important;
  text-align: center;
}

.ui.selection.dropdown > .dropdown.icon {
  display: none;
}

.columnModal {
  float: left;
  width: 33.33%;
  padding: 10px;
  text-align: center;
}

.columnContent {
  text-align: center;
}

.columnTitle {
  text-align: center;
  font-size: var(--large);
  font-weight: 800;
}

.columnText {
  text-align: center;
  font-size: var(--large);
}

.messageSeparator {
  margin: 15px 0 0 0;
}

.contentAdvanceModal {
  content: "";
  display: table;
  clear: both;
  font-size: 1.28571429rem;
  width: 100%;
}

.advancedOptionsDescriptionLabel {
  margin-bottom: 20px;
}

.advancedOptionsLabelWrapper {
  padding: 0;
}

.advancedOptionsRadioNameLabel {
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  min-height: 20px;
  align-items: center;
}

.advancedOptionsRadioDescriptionLabel {
  margin: 0;
  color: #5d5d5d;
}

.advancedOptionsRecommendedSpan {
  display: flex;
}

.advancedOptionsRecommendedIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 5px;
}

.modalHeaderItem > label {
  margin: 5px 0;
  font-size: var(--normal) !important;
  color: var(--main-color) !important;
}

.modalTeamItem > label {
  margin: 5px 0;
  font-size: var(--large) !important;
}

.modalTeamHeader {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.itemsDocumentContent {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin: 15px 0;
}

.itemDocument {
  text-align: center;
  cursor: auto;
  width: 30%;
  margin: 20px 5px;
}

.itemDocumentIcon {
  width: 100%;
}

.itemDocumentLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graphHint {
  background-color: var(--border-color);
  border-radius: 5px;
}

.graphHint > .hintTitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.graphHint > .hintTitle > h3 {
  font-size: var(--normal);
  color: var(--neutral-color);
  margin: 0;
  padding: 5px 10px 0 10px;
}

.graphHint > p {
  font-size: var(--small);
  color: var(--neutral-color);
  margin: 0;
  padding: 0 10px;
}

.graphHint > p:last-child {
  padding-bottom: 5px;
}

.modalVideoDescription {
  color: var(--text-color-light);
  padding: 0 10px 10px 10px;
}

.ui.modal > .modalVideoContent {
  padding: 1.5rem 0 1.5rem 0;
}

.modalVideoButton {
  color: var(--main-color);
  font-size: var(--normal);
  background-color: var(--neutral-color);
  border: none;
  height: 36px;
}

.modalVideoButton:hover {
  background-color: #eee;
}

.modalVideoButton:active,
.modalVideoButton:focus {
  outline: none;
}

.modifyModalError {
  padding: 20px 0 0 0;
}

.instructtionsModal.ui.modal > .content > .ui.list > li {
  font-size: var(--large);
}

.descriptionInput {
  margin: 20px 0;
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer {
  display: flex;
}

.ui.modal.createNewScenarioModal > .content > .createNewScenarioConfirmationText {
  font-size: var(--large);
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemName {
  width: 55%;
  font-size: var(--normal);
  white-space: pre-wrap;
  padding: 5px;
}
.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemValue {
  width: 45%;
  font-size: var(--normal);
  white-space: pre-wrap;
  padding: 5px;
  font-weight: bold;
}

.ui.modal.createNewScenarioModal > .content > .ui.list > .item > .itemContainer > .itemValue > strong {
  overflow-wrap: break-word;
}

.contentMarginTop {
  margin-top: 1.5rem;
}

.contentMarginBottom {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 742px) {
  .inputContent {
    height: 42px;
  }

  .modalInputContent > .inputItem > .ui input {
    font-size: var(--small) !important;
  }

  .ui.selection.dropdown {
    min-width: 10vw !important;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .ui.modal > .modalVideoContent {
    padding: 1.5rem 0 1.5rem 0 !important;
  }

  .ui.modal > .modalVideoContent > iframe {
    height: 250px;
  }

  .modalInputContent {
    height: 36px;
  }
}
